import { easyDelete, easyGet, easyPost, easyPut } from "../../utils/fetch";
const API_URLUserGroup = `api/UserGroup/`;

export const deleteUserGroupByID = async (groupId) => {
  return await easyDelete(`${API_URLUserGroup}DeleteUserGroupByID/${groupId}`);
};

// edit
export const editUserGroup = async (id, data) => {
  return await easyPut(`api/UserGroup/EditUserGroup/${id}`, data);
};

export const fetchUserGroupList = async () => {
  const result = await easyGet(`${API_URLUserGroup}GetUserGroupList`);
  return result;
};
export const fetchUserGroupListWithUserList = async () => {
  const result = await easyGet(
    `${API_URLUserGroup}GetUserGroupListWithUserList`
  );
  return result;
};
export const fetchUserGroupListByUserID = async (id) => {
  const result = await easyGet(`${API_URLUserGroup}GetGroupListByUserID/${id}`);
  return result;
};
// add

export const addUserListToUserGroup = async (groupId, users) => {
  return await easyPost(`${API_URLUserGroup}LinkUserGroupWithUserList`, {
    userGroupId: groupId,
    userList: users,
  });
};

export const createNewUserGroup = async (text, users) => {
  return await easyPost(`${API_URLUserGroup}AddUserGroup`, {
    name: text,
    userList: users,
  });
};

// delete
export const deleteUserFromUserGroup = async (groupId, userid) => {
  return await easyDelete(`api/UserGroup/UnlinkUserFromUserGroup`, {
    userGroupId: groupId,
    userId: userid,
  });
};

//add
