// export type Props = {
//   value: string,
//   valueLength: number,
//   onChange: (value: string) => void,
// };

export default function OtpInput({ value, valueLength, onChange }) {
  return (
    <div className="flex w-full max-w-360 column-gap-10">
      {[1, 2, 3, 4, 5, 6].map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="w-full h-60 border-1 border-gray-300 rounded-md text-center text-2xl font-bold leading-none"
          value={digit}
        />
      ))}
    </div>
  );
}
