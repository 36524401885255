import { toast } from "react-toastify";

export const SuccessMsg = (text) => {
    toast.success(`${text}`, {
        style:{textTransform:"capitalize"},
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}
export const WarnMsg=(text)=> {
    toast.warn(`${text}`, {
        style:{textTransform:"capitalize"},
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}
export const ErrorMsg = (text) => {
    toast.error(`${text}`, {
        style:{textTransform:"capitalize"},
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}
export const InfoMsg = (text) => {
    toast.info(`${text}`, {
        style:{textTransform:"capitalize"},
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}
