import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UkFlag from "../../../assets/img/uk.png";
import TrFlag from "../../../assets/img/tr.png";
import DeFlag from "../../../assets/img/de.png";
import Tooltip from "../../tooltip";
import AuthApi from "api/auth";
import { useAuth } from "hooks/useAuth";
import EditUserDialog from "./EditUserDialog";
import { useToggle } from "hooks/useToogle";
import { getInitials } from "utils/string";
import { fallbackLng } from "utils/i18n";
import routeConfig from "config/route";

export default function RightBar() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { authUser } = useAuth();
  const editUserDialog = useToggle();

  const logout = () => {
    i18n.changeLanguage(fallbackLng);
    localStorage.clear();
    AuthApi.signOut();
    nav(routeConfig.login);
  };
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    i18n.language.split("-")[0]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const options = [
    { value: "en", label: "English", flag: UkFlag },
    { value: "tr", label: "Türkçe", flag: TrFlag },
    { value: "de", label: "Deutsch", flag: DeFlag },
  ];
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="h-screen mx-1 flex flex-col max-w-[80px]">
      <div className="py-4 mt-4 flex flex-col items-center">
        <Tooltip
          click="hover"
          name={
            <div className="rounded-full bg-babGray700 text-white p-2 w-12 h-12 flex justify-center items-center">
              {getInitials(authUser.name)}
            </div>
          }
          content={
            <div className="px-4 py-3">
              <p className="text-base font-medium text-gray-800">
                {i18n.t("common.userProfile")}
                <i
                  className="icon-edit ml-4"
                  onClick={() => editUserDialog.open()}
                />
              </p>
              <div className="flex justify-start align-items-start mt-3">
                <div className="rounded-full bg-babGray700 text-white p-2 w-10 h-10 flex justify-center items-center">
                  {getInitials(authUser.name)}
                </div>
                <div className="ml-2">
                  <p className="text-xs text-gray-800 font-medium text-capitalize">
                    {i18n.t("common.name")}
                  </p>
                  <p className="text-xs text-gray-800">{authUser.name}</p>
                  <p className="text-xs text-gray-800 font-medium mt-2 text-capitalize">
                    {i18n.t("common.email")}
                  </p>
                  <p className="text-xs text-gray-800">{authUser.email}</p>
                  <p className="text-xs text-gray-800 font-medium mt-2 text-capitalize">
                    {i18n.t("common.phone")}
                  </p>
                  <p className="text-xs text-gray-800">{authUser.phone}</p>
                </div>
              </div>
            </div>
          }
          type={"language"}
        />

        <p
          title={authUser.name}
          className="text-xs text-gray-800 text-center mt-2"
        >
          {authUser.name}
        </p>
        <div className="mt-3">
          <Tooltip
            click="hover"
            name={
              <button
                type="button"
                className="inline-flex justify-center items-center w-16 py-2 text-sm bg-white shadow-md rounded-md"
                onClick={toggleDropdown}
              >
                <img
                  src={
                    options.find((option) => option.value === selectedOption)
                      ?.flag
                  }
                  alt="Selected Flag"
                  className="w-5 h-4 mr-1"
                />{" "}
                <i className="icon-down"></i>
              </button>
            }
            content={
              <div onMouseLeave={() => setIsDropdownOpen(false)}>
                {options.map((option) => (
                  <button
                    key={option.value}
                    className="flex items-center w-full p-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 "
                    onClick={() => {
                      i18n.changeLanguage(option.value);
                      handleOptionChange(option.value);
                    }}
                  >
                    <img
                      src={option.flag}
                      alt={`${option.label} Flag`}
                      className="w-6 h-5 mr-2"
                    />
                    <span className="text-xs">{option.label}</span>
                  </button>
                ))}
              </div>
            }
            type={"language"}
          />
        </div>

        {/* <div className="space-y-5 text-xl flex flex-col items-center justify-center mt-7 text-slate-600 cursor-pointer">
          <Tooltip
            click="hover"
            name={<i className="icon-notification text-3xl"></i>}
            content="Notification"
            type={"rightmenu"}
          />
          <Tooltip
            click="hover"
            name={<i className="icon-messages text-3xl"></i>}
            content="Messages"
            type={"rightmenu"}
          />
          <Tooltip
            click="hover"
            name={<i className="icon-cloud text-3xl"></i>}
            content="Cloud"
            type={"rightmenu"}
          />
          <Tooltip
            click="hover"
            name={<i className="icon-setting text-3xl"></i>}
            content="Settings"
            type={"rightmenu"}
          />
        </div> */}
      </div>
      <div className="justify-center flex items-end h-full mb-5">
        <div className="" onClick={logout}>
          <Tooltip
            click="hover"
            name={<i className="icon-log-out text-3xl"></i>}
            content={t("logout")}
            type={"rightmenu"}
          />
        </div>
      </div>
      <EditUserDialog
        open={editUserDialog.opened}
        onClose={editUserDialog.close}
      />
    </div>
  );
}
