import React from "react";
import Menu from "components/Menu";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

const DeviceCard = ({
  device,
  onClick,
  onUsersMenuClick,
  onEventsMenuClick,
  onAddUserMenuClick,
  onMapMenuClick,
}) => {
  const { t } = useTranslation();
  const { authUser } = useAuth();
  const hasDeviceSharingPermission =
    device.owner === authUser.uid ||
    (device.owners ?? []).includes(authUser.uid) ||
    (device.shareOwners ?? []).includes(authUser.uid);

  const menuItems = [
    {
      icon: "icon-view-logs-tp",
      label: t("deviceLogs"),
      onClick: onEventsMenuClick,
    },
    {
      icon: "icon-all-users",
      label: t("usersOnTheDevice"),
      onClick: onUsersMenuClick,
    },
    {
      icon: "icon-create-user",
      label: t("addUser"),
      visible: hasDeviceSharingPermission,
      onClick: onAddUserMenuClick,
    },
    {
      icon: "icon-locations",
      label: t("showOnTheMap"),
      onClick: onMapMenuClick,
    },
  ];

  return (
    <div
      className={`flex flex-col items-center bg-white text-sm rounded-xl p-4 relative`}
    >
      <div className="absolute top-2 right-2">
        <Menu position="right" items={menuItems} />
      </div>
      <div onClick={onClick} className="bg-white rounded-xl">
        <div className="outline outline-slate-100 border rounded-full h-28 w-28 flex items-center justify-center bg-white shadow-md ">
          <div className="outline border-2 border-babGray300 outline-babGray100 rounded-full h-12 w-12 flex items-center justify-center shadow-md text-gray-400 font-extrabold tracking-wider">
            bab
          </div>
        </div>
      </div>
      <div onClick={onClick} className="text-babGray900 mt-3 text-center ">
        {device.name}
      </div>
    </div>
  );
};

export default DeviceCard;
