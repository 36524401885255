import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import { auth } from "../lib/firebase";

export default class AuthApi {
  static async signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  static async signOut() {
    return signOut(auth);
  }

  static async register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  static getAuthUser() {
    return auth.currentUser;
  }

  static sendPasswordResetEmail(email) {
    return sendPasswordResetEmail(auth, email, {
      url: "https://app.accessdonkey.com/login",
    });
  }

  static updatePassword(newPassword) {
    return updatePassword(this.getAuthUser(), newPassword);
  }
}
