import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
  userGroups: [],
  userListGroupById: [],
  selectedUserGroup: null,
  status: "idle",
};

export const userGroupSlice = createSlice({
  name: "userGroup",
  initialState,

  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    setUserListByGroupId: (state, action) => {
      state.userListGroupById = action.payload;
    },
    setSelectedUserGroup: (state, action) => {
      state.selectedUserGroup = action.payload;
    },
    addNewUserGroup: (state, action) => {
      state.userGroups.push(action.payload);
      console.log("******", action.payload);
      state.selectedUserGroup = action.payload;
      state.userListGroupById.push(action.payload);
    },
    deleteUserGroup: (state, action) => {
      const filtered = state.userGroups.filter(
        (item) => item.ID !== action.payload
      );
      state.userGroups = filtered;
      state.selectedUserGroup=null;
    },
    edituserGroup: (state, action) => {
      const indexOf = state.userGroups.findIndex(
        (item) => item.ID === action.payload.ID
      );
      state.userGroups[indexOf].Name = action.payload.Name;
    },
    deleteUser: (state, action) => {
      const filtered = state.allUsers.Data.filter(
        (item) => item.ID !== action.payload
      );
      state.allUsers.Data = filtered;
    },
    addUserToUsergroup: (state, action) => {
      state.userListGroupById.Data.push(action.payload)
    },
    editUser: (state, action) => {
      const indexOf = state.allUsers.Data.findIndex(
        (item) => item.ID === action.payload.id
      );
      state.allUsers.Data[indexOf].FirstName = action.payload.firstName;
      state.allUsers.Data[indexOf].LastName = action.payload.lastName;
      state.allUsers.Data[indexOf].PhoneNumber = action.payload.phoneNumber;
      state.allUsers.Data[indexOf].Email = action.payload.email;
    },
  },
});

export const {
  setUserGroups,
  edituserGroup,
  setUserListByGroupId,
  setSelectedUserGroup,
  addNewUserGroup,
  addUsers,
  deleteUserGroup,
  deleteUser,
  setAllUsers,
  addUserToUsergroup,
  editUser
} = userGroupSlice.actions;

export default userGroupSlice.reducer;
