import { useAuth } from "../hooks/useAuth";
import AppLoader from "../components/AppLoader";
import AuthLayout from "./AuthLayout";
import GuestLayout from "./GuestLayout";

export default function Layout() {
  const { authUser, isAuthUserLoading } = useAuth();

  if (isAuthUserLoading) {
    return <AppLoader />;
  }

  if (!authUser) {
    return <GuestLayout />;
  }

  return <AuthLayout />;
}
