import React from "react";

const DialogTitle = ({ children }) => {
  return (
    <div className="pb-4 flex justify-center rounded-t">
      <div className="text-xl text-center font-medium">{children}</div>
    </div>
  );
};

export default DialogTitle;
