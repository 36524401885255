import React, { useState, useEffect } from "react";
import FixBarL from "./fixbar";
import { useLocation } from "react-router-dom";

export default function SidebarL(props) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(""); //ortak tetiklenen alan burası fixleftten tetikleniyor activemenu statetinide content icinde kullanıyoruz
  //bazı menulerde sidebar acılmayacak
  const [menuid, setMenuid] = useState(null);
  const path = location.pathname.replace("/", "");
  const formattedPath = path.charAt(0).toUpperCase() + path.slice(1);

  useEffect(() => {
    const storedSidebarState = localStorage.getItem("sidebarOpen");
    if (storedSidebarState === "true") {
      props.setOpenBar(true);
      props.setIssidebar(true);
    } else {
      props.setOpenBar(false);
      props.setIssidebar(false);
    }
    setActiveMenu(formattedPath);
  }, [path]);

  return (
    <>
      {props.isSidebar === true && (
        <div
          className={` text-slate-600 text-2xl rounded-xl absolute left-[432px] top-1/2 z-10 duration-200 py-7
        ${
          props.openBar === false
            ? "rotate-180 text-2xl -translate-x-[354px] bg-white"
            : "bg-white"
        } cursor-pointer`}
          onClick={() => props.setOpenBar(!props.openBar)}
        >
          <i className="icon-left text-2xl text-babGray900"></i>
        </div>
      )}
      <div
        className={`flex ${
          false ? "w-[35rem]" : "w-[6.5rem] bg-babGray50"
        } duration-300`}
      >
        <FixBarL
          setIssidebar={props.setIssidebar}
          setShowSidebar={props.setOpenBar}
          showSidebar={props.openBar}
          setActiveMenu={setActiveMenu}
          menuid={menuid}
          setMenuid={setMenuid}
        />
        {/* {activeMenu === "Locations" && (
          <SidebarLocationContent activeMenu={activeMenu} />
        )}
        {activeMenu === "Users" && (
          <SidebarUserContent activeMenu={activeMenu} />
        )}
        {activeMenu === "Credentials" && (
          <SidebarCredentialContent activeMenu={activeMenu} />
        )} */}
      </div>
    </>
  );
}
