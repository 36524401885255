import { LoadAnimation } from "./LoadAnimation";
import Donkey from "../assets/img/sidebar/donkey.svg";

const AppLoader = () => (
  <>
    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 transition-opacity z-10"></div>
    <div className="absolute  right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2 z-20">
      <div className="flex justify-center">
        <img alt="logosm" width={46} height={50} src={Donkey} />
      </div>
      <LoadAnimation
        viewType={"border-dotted"}
        bordersize={"border-5"}
        Color={"border-white"}
        sizeH={"h-20"}
        sizeW={"w-20"}
      />
    </div>
  </>
);

export default AppLoader;
