import PhoneInput2 from "react-phone-input-2";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import "react-phone-input-2/lib/style.css";
import i18n from "utils/i18n";

export default function PhoneInput({
  id,
  name,
  label = i18n.t("common.phone"),
  value,
  onChange,
  country = "tr",
  error,
  placeholder,
}) {
  const locales = { tr, de, en: undefined };
  const locale = locales[i18n.language];
  return (
    <div className="relative">
      <PhoneInput2
        localization={locale}
        enableSearch
        disableSearchIcon
        specialLabel={label}
        containerStyle={{ zIndex: 11 }}
        containerClass="[&>.flag-dropdown]:!border-0 "
        placeholder={placeholder}
        inputProps={{ ...(id || name ? { id: id || name } : {}) }}
        inputClass="py-2 !w-full !block pl-4 !rounded-full !border-2 !h-8 !appearance-none !bg-transparent focus:!outline-none focus:!ring-0 focus:!border-babGreen200 placeholder:!text-babGray400 placeholder:!italic peer"
        country={country}
        value={value}
        onChange={(value, data, event, formattedValue) =>
          onChange(value ? `+${value}` : "", data, event, formattedValue)
        }
      />
      <label
        className={`text-babGray900 absolute duration-300 not-italic peer-placeholder-shown:italic peer-focus:not-italic peer-focus:text-babGray900
        transform -translate-y-6 top-0 z-10 origin-[0] pl-2 peer-placeholder-shown:text-babGray400
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-1/2 peer-placeholder-shown:top-1/2
           peer-focus:top-0 peer-focus:-translate-y-6 left-1`}
      >
        {label}
      </label>
      {error ? <p className="text-red-600 text-sm pl-2">{error}</p> : null}
    </div>
  );
}
