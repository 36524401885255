import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/homePage/logoHome.svg";
import OtpInput from "../common/OtpInput";

const Guest = () => {
  const [otp, setOtp] = useState("");
  return (
    <div className="lg:flex min-h-screen ">
      <div className=" flex-column w-full grid ">
        <div className="flex justify-between lg:ml-16 ml-4  mr-16 lg:my-0 my-4">
          <Link to={"/"}>
            <img src={Logo} alt="" className="w-48 " />
          </Link>
        </div>
        <div className="flex item-center justify-center">
          <div className="">
            <OtpInput
              value={otp}
              valueLength={6}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="bg-registerImg w-full h-screen bg-cover bg-center bg-no-repeat"></div>
    </div>
  );
};

export default Guest;
