import React from "react";
import { LoadAnimation } from "../LoadAnimation";

export default function InfoCard(props) {

  return (
    <div className="rounded-xl bg-white shadow-md w-full">
      <div className="p-4">
        <div className="flex">
          <div className="text-3xl text-slate-600">{props.logo}</div>
          {props?.isLoading && (
            <div className="ml-auto relative">
              <LoadAnimation
                viewType={"border-dotted"}
                bordersize={"border-4"}
                Color={"border-babGray400"}
                sizeH={"h-7"}
                sizeW={"w-7"}
              />
            </div>
          )}
        </div>
        <div>
          <div className="text-lg">
            <span className="font-medium text-4xl text-slate-600">
              {props.data}
            </span>{" "}
            {props.name}
          </div>
        </div>
        <div className="sm:text-sm text-xs text-babGreen700 italic">
          {props.liveData}
        </div>
      </div>
    </div>
  );
}
