import { useState } from "react";

const useToggle = (config = { data: null, opened: false }) => {
  const [state, setState] = useState(() => ({
    opened: !!config.opened,
    closed: !config.opened,
    data: config.data ?? null,
  }));

  const open = (data) => {
    setState({ opened: true, closed: false, data: data ?? null });
  };

  const close = () => {
    setState({ opened: false, closed: true, data: null });
  };

  const toggle = (data) => {
    if (state.opened) {
      close();
    } else {
      open(data);
    }
  };

  const setData = (data) => {
    setState((state) => ({ ...state, data }));
  };

  return {
    ...state,
    open,
    close,
    toggle,
    setData,
  };
};

export { useToggle };
