var types = {
  0: "Building",
  1: "Floor",
  2: "Zone",
  3: "Room",
  4:"Others"
};
export var placeTypeArray = Object.keys(types).map(function (type) {
  return types[type];
});
