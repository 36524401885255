import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
  selectedLocation: null,
  selectedPlace: null,
  status: "idle",
};

export const locationsSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.locations = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
      state.selectedPlace = action.payload?.PlaceList[0];
    },
    setSelectedPlace: (state, action) => {
      state.selectedPlace = action.payload;
    },
    addLocation: (state, action) => {
      state.locations.push(action.payload);
      state.selectedLocation = action.payload;
    },
    deleteLocation: (state, action) => {
      const filtered = state.locations.filter(
        (item) => item.ID !== action.payload
      );
      state.locations = filtered;
      state.selectedLocation = null;
      state.selectedPlace = null;
    },
    editLocation: (state, action) => {
      const indexOf = state.locations.findIndex(
        (item) => item.ID === action.payload.ID
      );
      state.locations[indexOf].Name = action.payload.Name;
      state.selectedLocation.Name = action.payload.Name;
      state.locations[indexOf].Address = action.payload.Address;
      state.selectedLocation.Address = action.payload.Address;
    },
    addPlace: (state, action) => {
      state.selectedLocation.PlaceList.push(action.payload);
      state.selectedPlace = action.payload;
      const index = state.locations.findIndex(
        (item) => item.ID === state.selectedLocation.ID
      );
      state.locations[index].PlaceList.push(action.payload);
    },
    deletePlace: (state, action) => {
      const index = state.locations.findIndex(
        (item) => item.ID === state.selectedLocation.ID
      );
      const filtered = state.selectedLocation.PlaceList.filter(
        (item) => item.ID !== action.payload
      );
      state.locations[index].PlaceList = filtered;
      state.selectedPlace = state.locations[index].PlaceList[0];
    },
    editPlaces: (state, action) => {
      const indexOf = state.locations.findIndex(
        (item) => item.ID === action.payload.ParentID
      );
      const indexOfPlace = state.locations[indexOf].PlaceList.findIndex(
        (item) => item.ID === action.payload.ID
      );
      state.locations[indexOf].PlaceList[indexOfPlace].Name =
        action.payload.Name;
        state.locations[indexOf].PlaceList[indexOfPlace].PlaceType =
        action.payload.PlaceType;
        state.locations[indexOf].PlaceList[indexOfPlace].PlaceTypeAsString =
        action.payload.PlaceTypeAsString;
    },
  },
});

export const {
  setLocation,
  deleteLocation,
  addLocation,
  editLocation,
  setSelectedLocation,
  setSelectedPlace,
  // setPlaces,
  addPlace,
  deletePlace,
  editPlaces,
} = locationsSlice.actions;

export default locationsSlice.reducer;
