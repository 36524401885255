import React from "react";

export const LoadAnimation = (props) => {
  return (
    <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
      <div
        className={`border-t-transparent ${props.viewType} animate-spin  rounded-full ${props.Color} ${props.bordersize} ${props.sizeH} ${props.sizeW}`}
      ></div>
    </div>
  );
};
