import Dialog from "../Dialog";
import DialogTitle from "../DialogTitle";
import DialogContent from "../DialogContent";
import SearchInput from "../searchInput";
import ToggleButton from "../ToggleButton";
import ToggleButtonGroup from "../ToggleButtonGroup";
import { useState } from "react";
import { useGetAllUsersByIdsQuery } from "../../redux/user";
import { getInitials } from "../../utils/string";
import { useAuth } from "../../hooks/useAuth";
import { ArrayUtil } from "../../utils/array";
import { useTranslation } from "react-i18next";

const FilterStatus = {
  All: "All",
  Owners: "Owners",
  ShareOwners: "ShareOwners",
  AllowedUsers: "AllowedUsers",
};

export default function UsersDialog({
  open,
  onClose,
  device,
  onEditUserClick,
}) {
  const { t } = useTranslation();
  const { authUser } = useAuth();
  const [filterText, setFiltertext] = useState("");
  const [filterStatus, setFilterStatus] = useState(FilterStatus.All);

  const shareOwners = device?.shareOwners ?? [];
  const allowedUsers = device?.allowedUsers ?? [];
  const owners = device?.owners ?? [];

  const usersMap = {
    [FilterStatus.Owners]: owners,
    [FilterStatus.ShareOwners]: shareOwners,
    [FilterStatus.AllowedUsers]: allowedUsers,
    [FilterStatus.All]: ArrayUtil.union(owners, shareOwners, allowedUsers),
  };
  const userIds = usersMap[filterStatus];
  const { data, isFetching } = useGetAllUsersByIdsQuery(userIds, {
    skip: !open,
  });

  const users = data || [];
  const filteredUsers = users.filter((user) => {
    const userName = user.name.toLowerCase();

    return userName.indexOf(filterText) > -1;
  });
  const hasUsers = filteredUsers.length > 0;

  const findUserStatus = (id) => {
    if (device?.owner === id) {
      return t("owner");
    }
    if (owners.includes(id)) {
      return t("owners");
    }
    if (shareOwners.includes(id)) {
      return t("sharingAuthority");
    }
    if (allowedUsers.includes(id)) {
      return t("allowedUsers");
    }
    return "";
  };

  const editable = device?.owner === authUser.uid;

  const handleClose = () => {
    setFilterStatus(FilterStatus.All);
    onClose();
  };

  return (
    <Dialog
      width="max-w-[40rem]"
      height="max-h-[600px] h-[600px]"
      icon={<i className="icon-device text-5xl" />}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("usersOnTheDevice")}</DialogTitle>
      <DialogContent>
        <div className={`flex flex-col bg-white text-sm rounded-xl p-4`}>
          <SearchInput onChange={(e) => setFiltertext(e.target.value)} />
          <ToggleButtonGroup
            className="mt-2"
            value={filterStatus}
            onChange={setFilterStatus}
          >
            <ToggleButton value={FilterStatus.All}>{t("all")}</ToggleButton>
            <ToggleButton value={FilterStatus.Owners}>
              {t("owners")}
            </ToggleButton>
            <ToggleButton value={FilterStatus.ShareOwners}>
              {t("sharingAuthority")}
            </ToggleButton>
            <ToggleButton value={FilterStatus.AllowedUsers}>
              {t("allowedUsers")}
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="max-h-[340px] overflow-auto">
            <div className="space-y-2 mt-3">
              {!hasUsers && !isFetching && (
                <p className="text-center mt-5">{t("noUserFound")}</p>
              )}
              {isFetching && <p className="text-center mt-5">{t("loading")}</p>}
              {filteredUsers.map((user, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-gray-100 rounded-xl p-3"
                >
                  <div className="flex items-center">
                    <div className="rounded-full bg-babGray700 text-white p-2 w-10 h-10 flex justify-center items-center">
                      {getInitials(user.name)}
                    </div>
                    <div className="flex flex-col ml-3">
                      <h2 className="font-medium">{user.name}</h2>
                      <p>{findUserStatus(user.id)}</p>
                    </div>
                  </div>
                  {editable && (
                    <i
                      className="icon-edit text-3xl text-babGray600 cursor-pointer"
                      onClick={() => onEditUserClick({ user, device })}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
