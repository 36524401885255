import axios from "axios";

export const easyGet = async (path, data = {}) => {
  const response = await axios.get(process.env.REACT_APP_API_KEY + path, {
    data,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const easyPost = async (path, data = {}) => {
  const response = await axios.post(
    process.env.REACT_APP_API_KEY + path,
    data,
    {
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const easyPut = async (path, data = {}) => {
  const response = await axios.put(process.env.REACT_APP_API_KEY + path, data, {
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const easyDelete = async (path, data = {}) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_KEY + path,
    { data },
    {
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};
