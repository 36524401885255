import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessPlanListBygateID:[],
  accessPlanList: [],
  status: "idle",
};

export const AccessPlanSlice = createSlice({
  name: "accessPlan",
  initialState,

  reducers: {
    setAllAccessPlans: (state, action) => {
      state.accessPlanList = action.payload;
    },
    setAllAccessPlanBygateID: (state, action) => {
      state.accessPlanListBygateID = action.payload;
    },
    addAccessPlan: (state, action) => {
      state.accessPlanList.push(action.payload);
    },
    deleteAccessPlan: (state, action) => {
      const filtered = state.accessPlanList.filter(
        (item) => item.ID !== action.payload
      );
      state.accessPlanList = filtered;
    },
    editplan: (state, action) => {
      const indexOf = state.accessPlanList.findIndex(
        (item) => item.ID === action.payload.id
      );
      state.accessPlanList[indexOf].Title = action.payload.title;
      state.accessPlanList[indexOf].StartDate = action.payload.startDate;
      state.accessPlanList[indexOf].EndDate = action.payload.endDate;
    },
  },
});

export const {
  setAllAccessPlans,
  addAccessPlan,
  deleteAccessPlan,
  setAllAccessPlanBygateID,
  editplan
} = AccessPlanSlice.actions;

export default AccessPlanSlice.reducer;
