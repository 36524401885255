import React from "react";
import { useTranslation } from "react-i18next";

const Table = (props) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white h-[39rem] rounded-xl p-3">
      <div>{props?.filterHeader}</div>
      <div className=" h-[30rem] pl-5">
        <table className="w-full flex flex-col h-full">
          <thead className="bg-white text-babGray900">
            <tr>
              {props.th.map((header, idx) => {
                return (
                  <th className="" key={idx}>
                    <div className="flex items-center">
                      <div className="mr-2 font-extrabold">{header}</div>{" "}
                      <i className="icon-sort text-babGray400"></i>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="scrollbarY">
            {props.isLoading && (
              <tr>
                <td colSpan={props.tr.length}>
                  <div className="py-6 text-lg text-babGray600 tracking-wide flex justify-center items-center">
                    {t("common.loading")}
                  </div>
                </td>
              </tr>
            )}
            {props.tr.map((row, index) => {
              return (
                <tr key={index} className="text-sm text-babGray800 my-10">
                  {row.map((rowItem, i) => {
                    return (
                      <td
                        className={`font-medium ${
                          rowItem === "ACCESS_GRANTED" && "text-babGreen500"
                        } ${rowItem === "ACCESS_DENIED" && "text-babRed500"} `}
                        key={i}
                      >
                        {rowItem}{" "}
                        {(rowItem === null || rowItem === " ") && " - "}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {props.tr.length === 0 && (
              <tr>
                <td colSpan={props.tr.length}>
                  <p className="text-md mt-4 text-center text-babGray600">
                    {t("noDataFound")}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
