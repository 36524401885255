import GoogleMapReact from "google-map-react";
import Dialog from "components/Dialog";
import DialogTitle from "components/DialogTitle";
import DialogContent from "components/DialogContent";
import { useTranslation } from "react-i18next";

export default function MapDialog({ open, onClose, device }) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  if (!device || !device.location) {
    return null;
  }

  const position = {
    lat: device.location.latitude,
    lng: device.location.longitude,
  };

  return (
    <Dialog
      width="max-w-[50rem]"
      height="min-h-[10rem]"
      icon={<i className="icon-edit-user text-5xl" />}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("showOnTheMap")}</DialogTitle>
      <DialogContent>
        <h2 className="text-center py-3 font-medium">{device.name}</h2>
        <div className="h-[50vh] w-full">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDnc24O4R5k0JeRG6oMeV2ZQ0YU9_FSe0M",
            }}
            center={position}
            defaultCenter={position}
            defaultZoom={13}
          >
            <i
              lat={position.lat}
              lng={position.lng}
              className="icon-pin text-4xl text-red-500"
            />
          </GoogleMapReact>
        </div>
      </DialogContent>
    </Dialog>
  );
}
