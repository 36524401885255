import Dialog from "../Dialog";
import DialogTitle from "../DialogTitle";
import DialogContent from "../DialogContent";
import { useGetEventsQuery } from "../../redux/device";
import { useTranslation } from "react-i18next";
import { DateUtil } from "utils/date";

export default function EventsDialog({ open, onClose, device }) {
  const { t } = useTranslation();
  const { data, isFetching } = useGetEventsQuery(device?.serialNumber, {
    skip: !open,
  });

  const events = data || [];
  const hasEvents = events.length > 0;

  return (
    <Dialog
      width="max-w-[50rem]"
      icon={<i className="icon-device text-5xl" />}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t("deviceLogs")}</DialogTitle>
      <DialogContent>
        <div className={`flex flex-col bg-white text-sm rounded-xl p-4`}>
          <div className="max-h-[400px] overflow-auto">
            <div className="space-y-2 mt-3">
              {!hasEvents && !isFetching && (
                <p className="text-center mt-5">{t("noEventFound")}</p>
              )}
              {isFetching && <p className="text-center mt-5">{t("loading")}</p>}
              {events.map((event, index) => (
                <div
                  key={index}
                  className="flex flex-col bg-gray-100 rounded-xl p-3"
                >
                  <div className="flex items-center space-x-2">
                    <div className="outline outline-slate-100 border rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-md ">
                      <div className="outline border-2 border-babGray300 outline-babGray100 rounded-full h-6 w-6 flex items-center justify-center shadow-md text-gray-400 font-extrabold text-[8px] tracking-wider">
                        bab
                      </div>
                    </div>
                    <h2 className="font-medium text-lg">{event.device.name}</h2>
                  </div>
                  {event.location && (
                    <div className="flex items-center space-x-2 mt-3">
                      <i className="icon-pin text-xl text-babGray700" />
                      <p>{`${event.location.city} ${event.location.county}`}</p>
                    </div>
                  )}
                  <div className="flex justify-between mt-3">
                    {event.timestamp && (
                      <div className="flex items-center space-x-2">
                        <i className="icon-date-access-plan text-xl text-babGray700" />
                        <p>
                          {DateUtil.toDateTimeString(event.timestamp.seconds)}
                        </p>
                      </div>
                    )}
                    {event.user && (
                      <div className="flex items-cente space-x-2">
                        <i className="icon-dashboard-profile text-xl text-babGray700" />
                        <p>{event.user.name}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
