import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout/Layout";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Layout />
    </>
  );
};
export default App;
