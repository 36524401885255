import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import BabButton from "../common/BabButton/index.js";
import Bbinput from "../common/B-input/input.js";
import { useTranslation } from "react-i18next";
import AuthApi from "api/auth.js";
import { ErrorMsg, SuccessMsg } from "components/Toastify.js";
import routeConfig from "config/route.js";

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const nav = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required().email().label("common.email"),
      })
    ),
  });

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      await AuthApi.sendPasswordResetEmail(form.email);
      SuccessMsg(t("emailSentSuccessfully"));
    } catch (error) {
      ErrorMsg(t("anErrorOccuredWhileSendingEmail"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-3 items-center justify-center flex-col flex">
        <div className="flex flex-col items-center justify-center text-5xl font-bold text-center mb-5">
          {t("forgotYourPassword")}?
          <div className="text-lg text-gray-400 font-medium">
            {t("dontWorry")}!
            <br /> {t("weWillSendResetEmail")}
          </div>
        </div>
        <div className="w-[28rem]">
          <div className="w-full space-y-10 mb-10">
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.email")}
                  type="email"
                  name="email"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <BabButton
            isDisabled={loading}
            clickHandler={handleSubmit(onSubmit)}
            className="bg-black w-full rounded-full text-white font-medium py-2"
            id={"btnLogin"}
            type={"Submit"}
            value={t("resetPassword")}
          />
          <div
            className="flex justify-center items-center mt-40"
            onClick={() => {
              nav(routeConfig.login);
            }}
          >
            <div className="border-2 border-black rounded-full px-4 py-1 cursor-pointer">
              {t("common.back")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
