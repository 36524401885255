import React from "react";


const BabButton = (props) => {
  const btnEnableDisable = !props.isDisabled ? "btn-enable" : "btn-disabled";

  return (
    <button
      id={props.id}
      className={`btn ${btnEnableDisable} ${props.className}`}
      onClick={()=>props.clickHandler()}
      type={props.type}
      disabled={props.isDisabled}
    >
      {props.value}
    </button>
  );
};

BabButton.defaultProps = {
  type: "button",
  disabled: false,
};

export default BabButton;




