import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/img/homePage/logoHome.svg";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import routeConfig from "config/route";

const LoginRegister = () => {
  const location = useLocation();

  const checkRoute = (routeName) => location.pathname.startsWith(routeName);

  const isLoginRoute = checkRoute(routeConfig.login);
  const isSignupRoute = checkRoute(routeConfig.signup);
  const isForgotPasswordRoute = checkRoute(routeConfig.forgotPassword);

  return (
    <div className="lg:flex min-h-screen ">
      <div className=" flex-column w-full grid ">
        <div className="flex justify-between lg:ml-16 ml-4  mr-16 lg:my-0 my-4">
          <Link to={"/"}>
            <img src={Logo} alt="" className="w-48 " />
          </Link>
        </div>
        <div className="flex item-center justify-center">
          <div className="">
            {isLoginRoute && <LoginForm />}
            {isSignupRoute && <SignupForm />}
            {isForgotPasswordRoute && <ForgotPasswordForm />}
            <div className="">
              <div className="text-slate-500 font-medium p-1 mt-8 flex justify-center items-center">
                <div className="text-sm">
                  {" "}
                  By signing up you are agreeing to our{" "}
                </div>
                <Link
                  to={routeConfig.termsConditions}
                  className=" hover:text-sky-700 ml-1"
                >
                  {" "}
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-registerImg w-full h-screen bg-cover bg-center bg-no-repeat"></div>
    </div>
  );
};

export default LoginRegister;
