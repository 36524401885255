const routeConfig = {
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  guest: "/guest",
  termsConditions: "/terms-conditions",
  dashboard: "/dashboard",
  users: "/users",
  devices: "/devices",
};

export default routeConfig;
