import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  credentialList: [],
  selectedCredential: null,
  selectedCredentialType: null,
  credentialTypes: [],
  status: "idle",
};

export const CredentialSlice = createSlice({
  name: "credential",
  initialState,

  reducers: {
    setAllCredentials: (state, action) => {
      state.credentialList = action.payload;
    },
    addCredential: (state, action) => {
      state.credentialList.push(action.payload);
    },
    deleteCredential: (state, action) => {
      const filtered = state.credentialList.filter(
        (item) => item.ID !== action.payload
      );
      state.credentialList = filtered;
    },
    setSelectedCredential: (state, action) => {
      state.selectedCredential = action.payload;
    },
    setSelectedCredentialType: (state, action) => {
      state.selectedCredentialType = action.payload;
    },
    editCredential: (state, action) => {
      const indexOf = state.credentialList.findIndex(
        (item) => item.ID === action.payload.ID
      );
      state.credentialList[indexOf].Name = action.payload.Name;
      state.credentialList[indexOf].RAWData = action.payload.RAWData;
      state.credentialList[indexOf].SensorType = action.payload.SensorType;
      state.credentialList[indexOf].UserID = action.payload.UserID;
    },
  },
});

export const {
  setAllCredentials,
  addCredential,
  deleteCredential,
  setSelectedCredential,
  editCredential,
  setCredentialsByType,
  setSelectedCredentialType,
} = CredentialSlice.actions;

export default CredentialSlice.reducer;
