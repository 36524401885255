import React from "react";

const Dialog = ({
  open,
  onClose,
  children,
  icon,
  width = "max-w-[33rem]",
  height = "min-h-[28rem]",
}) => {
  if (!open) return null;

  return (
    <div className="items-center justify-center flex flex-col fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-25 bg-black">
      <div className={`w-full relative ${width}`}>
        <div className="absolute -right-4 -top-4 z-50 ">
          <button
            className="flex ml-auto bg-white rounded-lg p-2 shadow-lg"
            onClick={onClose}
          >
            <i className="icon-false text-xl"></i>
          </button>
        </div>
        <div
          className={`border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-y-auto overflow-x-hidden scrollbarY2 ${height}`}
        >
          <div className="flex justify-center mt-3">{icon}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
