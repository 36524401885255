import DeviceApi from "../api/device";
import { api } from "./api";

export const {
  useGetAllDevicesQuery,
  useLazyGetAllDevicesQuery,
  useGetEventsQuery,
  useUpdateDeviceMutation,
  useGetAllEventsQuery,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDevices: builder.query({
      queryFn: async () => {
        const { data, error } = await DeviceApi.getAll();
        if (error) {
          return { error };
        }
        return { data };
      },
      providesTags: ["devices"],
    }),
    getEvents: builder.query({
      queryFn: async (serialNumber) => {
        const { data, error } = await DeviceApi.getEvents(serialNumber);
        if (error) {
          return { error };
        }
        return { data };
      },
      providesTags: ["deviceEvents"],
    }),
    getAllEvents: builder.query({
      queryFn: async (args) => {
        const { data, error } = await DeviceApi.getAllEvents(
          args?.startDate,
          args?.endDate
        );
        if (error) {
          return { error };
        }
        return { data };
      },
      providesTags: ["allDeviceEvents"],
    }),
    updateDevice: builder.mutation({
      queryFn: async (deviceData) => {
        const { data, error } = await DeviceApi.update(deviceData);
        if (error) {
          return { error };
        }
        return { data };
      },
      invalidatesTags: ["devices"],
    }),
  }),
});
