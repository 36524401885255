import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import DeviceApi from "./device";
import { ArrayUtil } from "utils/array";

export default class UserApi {
  static async create(id, userData) {
    return setDoc(doc(db, "users", id), userData);
  }

  static async getAll() {
    try {
      const { data: devices } = await DeviceApi.getAll();
      const userIds = ArrayUtil.union(
        devices.reduce((ids, device) => {
          if (device.owner) {
            ids.push(device.owner);
          }
          if (device.owners) {
            ids.push(...device.owners);
          }
          if (device.shareOwners) {
            ids.push(...device.shareOwners);
          }
          if (device.allowedUsers) {
            ids.push(...device.allowedUsers);
          }

          return ids;
        }, [])
      );
      const { data: users } = await UserApi.getAllByIds(userIds);

      return { data: users };
    } catch (error) {
      return { error };
    }
  }

  static async getByEmail(email) {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      return {
        data: { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id },
      };
    }
    return { error: new Error("User Not Found!") };
  }

  static async getAllByIds(userIds) {
    if (userIds.length === 0) return { data: [] };

    const users = await Promise.all(
      userIds.map(async (id) => {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return { ...docSnap.data(), id: docSnap.id };
        }
        return null;
      })
    );

    return { data: users.filter((user) => user) };
  }

  static async update({ id, ...data }) {
    const userRef = doc(db, "users", id);
    if (!userRef) {
      return { data: null };
    }
    try {
      await updateDoc(userRef, data);
      return { data: id };
    } catch (error) {
      return { error };
    }
  }
}
