export const firebaseConfig = {
  apiKey: "AIzaSyAkVnca6n0ZcRUVEoE-hYn2BWs6QGJutGU",
  authDomain: "bab-access.firebaseapp.com",
  databaseURL: "https://bab-access.firebaseio.com",
  projectId: "bab-access",
  storageBucket: "bab-access.appspot.com",
  messagingSenderId: "549912686209",
  appId: "1:549912686209:web:20c9b4b4ee2be2254ae780",
  measurementId: "G-1Y7PNB1FS6",
};
