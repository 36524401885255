import React from "react";

export default function Bbinput(props) {
  const onValidationChange = (event) => {
    if (!props.validationHandler) return;
    const { value } = event.target;
    let errorMessage = "";
    if (!value) {
      errorMessage = `Please enter.`;
    } else if (value) {
      errorMessage = `Please enter valid.`;
    }
    props.validationHandler(props.type, errorMessage);
  };
  return (
    <div className="relative">
      <input
        autoComplete={props.autoComplete || "new-password"}
        ref={props.ref}
        name={`${props.name}`}
        type={`${props.type || "text"}`}
        {...(props.id || props.name ? { id: props.id || props.name } : {})}
        className={`py-2 w-full ${props.className}
         block pl-4  rounded-full border-2 ${props.name === "gap" && "h-8"}
         appearance-none bg-transparent
         focus:outline-none focus:ring-0 focus:border-babGreen200 peer placeholder:text-babGray400 placeholder:italic`}
        placeholder={props?.placeholder ? props?.placeholder : " "}
        onChange={(e) => props.onChange(props.name, e.target.value)}
        onBlur={(e) => onValidationChange(e)}
        value={props.value}
      />
      {props.endIcon && (
        <div className="absolute right-4 top-2">{props.endIcon}</div>
      )}

      <label
        htmlFor={`${props.id || props.name || ""}`}
        className={`text-babGray900 absolute duration-300 not-italic peer-placeholder-shown:italic peer-focus:not-italic peer-focus:text-babGray900
        transform -translate-y-6 top-0 z-10 origin-[0] pl-2 peer-placeholder-shown:text-babGray400
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-1/2 peer-placeholder-shown:top-1/2
           peer-focus:top-0 peer-focus:-translate-y-6 left-1`}
      >
        {props.title}
      </label>
      {props.error ? (
        <p className="text-red-600 text-sm pl-2">{props.error}</p>
      ) : null}
    </div>
  );
}
