import { useToggle } from "hooks/useToogle";
import { cloneElement } from "react";

const Menu = ({
  items,
  activator = (
    <i className="icon-three-points text-babGray900 text-xl cursor-pointer" />
  ),
  position = "right",
}) => {
  const menu = useToggle();

  return (
    <div className="relative">
      {cloneElement(activator, {
        onClick: () => menu.open(),
      })}
      {menu.opened && (
        <div
          className={`absolute -mt-6 text-sm rounded-lg z-40 cursor-pointer bg-white shadow-md border-slate-100 border-2 p-3 text-slate-500 w-60 ${
            position === "right" ? "right-0" : "left-0"
          }`}
          onMouseLeave={menu.close}
        >
          <div className="space-y-2">
            {items.map((option, index) => {
              const visible = option.visible ?? true;
              if (!visible) return null;

              return (
                <div
                  key={index}
                  className="flex items-center hover:bg-babGray100 p-2 rounded-xl"
                  onClick={option.onClick}
                >
                  {option.icon && (
                    <i className={`mr-2 text-lg ${option.icon}`} />
                  )}
                  <p>{option.label}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
