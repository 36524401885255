import {
  getDocs,
  collection,
  where,
  query,
  or,
  and,
  updateDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import AuthApi from "./auth";

export default class DeviceApi {
  static async getAll() {
    const authUser = AuthApi.getAuthUser();
    if (!authUser) {
      return { data: [] };
    }
    const devicesRef = collection(db, "devices");
    const q = query(
      devicesRef,
      or(
        where("owner", "==", authUser.uid),
        where("owners", "array-contains", authUser.uid),
        where("allowedUsers", "array-contains", authUser.uid),
        where("shareOwners", "array-contains", authUser.uid)
      )
    );
    try {
      const querySnapshot = await getDocs(q);
      return {
        data: querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      };
    } catch (error) {
      return { error };
    }
  }

  static async getEvents(serialNumber, startDate, endDate) {
    if (!serialNumber) {
      return { data: [] };
    }
    const queryConstraints = [where("device.serialNumber", "==", serialNumber)];
    if (startDate) {
      queryConstraints.push(where("timestamp", ">=", startDate));
    }
    if (endDate) {
      queryConstraints.push(where("timestamp", "<=", endDate));
    }
    const eventsRef = collection(db, "events");
    const q = query(
      eventsRef,
      and(...queryConstraints),
      orderBy("timestamp", "desc")
    );

    try {
      const querySnapshot = await getDocs(q);
      return { data: querySnapshot.docs.map((doc) => doc.data()) };
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  static async getAllEvents(startDate, endDate) {
    try {
      const { data: devices } = await DeviceApi.getAll();
      const values = await Promise.all(
        devices
          .filter((device) => device.serialNumber)
          .map((device) =>
            DeviceApi.getEvents(device.serialNumber, startDate, endDate)
          )
      );
      return {
        data: values
          .map((value) => value.data)
          .reduce((events, value) => events.concat(value), []),
      };
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  static async update({ id, ...data }) {
    const deviceRef = doc(db, "devices", id);
    if (!deviceRef) {
      return { data: null };
    }
    try {
      await updateDoc(deviceRef, data);
      return { data: id };
    } catch (error) {
      return { error };
    }
  }
}
