import React from "react";

export default function UserCard(props) {
  return (
    <div
      onClick={props.onClick}
      className={`relative bg-white rounded-xl shadow-md shadow-gray-200 p-2 scrollVisible `}
    >
      <div className="w-full justify-center my-2 flex">
        <i className="icon-users-groups text-6xl m-2"></i>
      </div>
      <div
        className={`overflow-x-scroll  scrollbarCard cursor-pointer h-[75px]`}
      >
        <div className="flex flex-col ">
          <div className="text-center font-medium whitespace-nowrap capitalize">
            {props.user.name}
          </div>
          <div className="text-sm text-babGray600 text-center">
            {props.user.phone}
          </div>
          <div className="text-sm text-babGray600 whitespace-nowrap text-center">
            {props.user.email}
          </div>
        </div>
      </div>
    </div>
  );
}
