import UserApi from "../api/user";
import { api } from "./api";

export const {
  useGetAllUsersQuery,
  useGetAllUsersByIdsQuery,
  useLazyGetAllUsersByIdsQuery,
  useGetByEmailQuery,
  useLazyGetByEmailQuery,
  useUpdateUserMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      queryFn: async () => {
        const { data, error } = await UserApi.getAll();
        if (error) {
          return { error };
        }
        return { data };
      },
      providesTags: ["users"],
    }),
    getAllUsersByIds: builder.query({
      queryFn: async (userIds) => {
        const { data, error } = await UserApi.getAllByIds(userIds);
        if (error) {
          return { error };
        }
        return { data };
      },
      providesTags: ["users", "ids"],
    }),
    getByEmail: builder.query({
      queryFn: async (email) => {
        const { data, error } = await UserApi.getByEmail(email);
        if (error) {
          return { error };
        }
        return { data };
      },
    }),
    updateUser: builder.mutation({
      queryFn: async (userData) => {
        const { data, error } = await UserApi.update(userData);
        if (error) {
          return { error };
        }
        return { data };
      },
      invalidatesTags: ["users"],
    }),
  }),
});
