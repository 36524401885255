import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import BabButton from "../common/BabButton/index";
import Bbinput from "../common/B-input/input";
import { ErrorMsg, SuccessMsg } from "../Toastify";
import AuthApi from "../../api/auth";
import { useTranslation } from "react-i18next";
import routeConfig from "config/route";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { email: "", password: "" },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required().email().label("common.email"),
        password: yup.string().required().label("common.password"),
      })
    ),
  });

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      await AuthApi.signIn(form.email, form.password);
      SuccessMsg(t("loggedInSuccessfully"));
    } catch {
      ErrorMsg(t("emailOrPasswordInvalid"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-3 items-center justify-center flex-col flex">
      <div className="flex flex-col items-center justify-center text-5xl font-bold text-center mb-5">
        Manage your space <br /> with us!
        <div className="text-lg text-gray-400 font-medium mt-1">
          Enable incredible experiences with a unified
          <br /> hardware and software solution
        </div>
      </div>
      <div className="space-y-10  w-[28rem]">
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Bbinput
              autoComplete="on"
              title={t("common.email")}
              type="email"
              name="email"
              onChange={(name, value) => onChange(value)}
              value={value}
              error={errors.email?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Bbinput
              autoComplete="on"
              title={t("common.password")}
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={(name, value) => onChange(value)}
              value={value}
              error={errors.password?.message}
              endIcon={
                <i
                  className={`icon ${
                    showPassword ? "icon-eye-off" : "icon-eye"
                  } text-xl cursor-pointer`}
                  onClick={() => setShowPassword(!showPassword)}
                />
              }
            />
          )}
        />
        <div className="flex mt-3 pr-1 text-sm  ">
          <div
            className="ml-auto cursor-pointer"
            onClick={() => nav(routeConfig.forgotPassword)}
          >
            {" "}
            {t("forgotPassword")} ?{" "}
          </div>
        </div>
        <BabButton
          className=" bg-black w-full rounded-full text-white font-medium py-2"
          id={"btnLogin"}
          type={"Submit"}
          value={"LOGIN"}
          isDisabled={loading}
          clickHandler={handleSubmit(onSubmit)}
        />
        <div className="cursor-pointer mt-4 ml-2 text-sm text-slate-600 flex items-center">
          {t("dontHaveAnAccount")} ?{" "}
          <span
            className="text-babGreen300 underline font-medium cursor-pointer ml-1"
            onClick={() => {
              nav(routeConfig.signup);
            }}
          >
            {t("signup")}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
