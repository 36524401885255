import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import BabButton from "../common/BabButton/index.js";
import Bbinput from "../common/B-input/input.js";
import { ErrorMsg, SuccessMsg } from "../Toastify.js";

import AuthApi from "../../api/auth.js";
import UserApi from "../../api/user.js";
import { useTranslation } from "react-i18next";
import i18n from "utils/i18n.js";
import PhoneInput from "components/PhoneInput";
import routeConfig from "config/route.js";

const errorMessages = {
  "auth/email-already-in-use": i18n.t("emailAlreadyInUse"),
  "auth/weak-password": i18n.t("weakPassword"),
};

const SignupForm = () => {
  const [countryCode, setCountryCode] = useState("TR");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const nav = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    context: { countryCode },
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required().label("common.firstName"),
        lastName: yup.string().required().label("common.lastName"),
        phone: yup.string().required().phone().label("common.phone"),
        email: yup.string().required().email().label("common.email"),
        password: yup.string().required().password().label("common.password"),
        confirmPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null], t("passwordsMustMatch"))
          .label("common.confirmPassword"),
      })
    ),
  });

  const onSubmit = async (form) => {
    const { firstName, lastName, phone, email, password } = form;
    try {
      setLoading(true);
      const { user } = await AuthApi.register(email, password);
      await UserApi.create(user.uid, {
        email,
        phone,
        name: `${firstName} ${lastName}`,
        token: user.accessToken,
      });
      SuccessMsg(t("successfullRegistration"));
      nav(routeConfig.login);
    } catch (error) {
      const errorMessage = errorMessages[error.code] || t("failedRegistration");
      ErrorMsg(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-3 items-center justify-center flex-col flex">
        <div className="flex flex-col items-center justify-center text-5xl font-bold text-center mb-5">
          Hello ! <br /> Start your gates with us!
          <div className="text-lg text-gray-400 font-medium mt-1">
            Fill in the fields below to create
          </div>
        </div>
        <div className="w-[28rem]">
          <div className="w-full space-y-10 mb-3">
            <Controller
              name="firstName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.firstName")}
                  type="text"
                  name="firstName"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.firstName?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.lastName")}
                  type="text"
                  name="lastName"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.lastName?.message}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  name="phone"
                  value={value}
                  onChange={(value, data) => {
                    setCountryCode(data?.countryCode || "TR");
                    onChange(value);
                  }}
                  error={errors.phone?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.email")}
                  type="email"
                  name="email"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.password")}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.password?.message}
                  endIcon={
                    <i
                      className={`icon ${
                        showPassword ? "icon-eye-off" : "icon-eye"
                      } text-xl cursor-pointer`}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  }
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.confirmPassword?.message}
                  endIcon={
                    <i
                      className={`icon ${
                        showConfirmPassword ? "icon-eye-off" : "icon-eye"
                      } text-xl cursor-pointer`}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  }
                />
              )}
            />
          </div>
          <BabButton
            clickHandler={handleSubmit(onSubmit)}
            className="bg-black w-full rounded-full text-white font-medium py-2"
            id={"btnLogin"}
            type={"Submit"}
            value={"Sign Up"}
            isDisabled={loading}
          />
          <div className="cursor-pointer mt-4 ml-2 text-sm text-slate-600 flex items-center">
            {t("alreadyHaveAnAccount")}?
            <div
              className="text-bab-green100 underline ml-1"
              onClick={() => {
                nav(routeConfig.login);
              }}
            >
              {t("login")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
