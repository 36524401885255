import axios from "axios";

const setupInterceptors = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const state = store?.getState();
      const token = state?.auth?.token;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const AxiosConfig = {
  setupInterceptors,
};

export default AxiosConfig;
