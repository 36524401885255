import { LoadAnimation } from "./LoadAnimation";

export default function LoadingButton({
  loading,
  onClick,
  children,
  className,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      className={`uppercase bg-babGray700 rounded-xl py-2 px-4 text-white font-medium relative ${className}`}
      onClick={onClick}
    >
      <p className={`${loading ? "invisible" : ""}`}>{children}</p>
      {loading && (
        <LoadAnimation
          viewType={"border-dotted"}
          bordersize={"border-3"}
          Color={"border-white"}
          sizeH={"h-6"}
          sizeW={"w-6"}
        />
      )}
    </button>
  );
}
