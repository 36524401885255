/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../lib/firebase";
import UserApi from "../api/user";

const initialState = { authUser: null, isAuthUserLoading: true };

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [isAuthUserLoading, setIsAuthUserLoading] = useState(
    initialState.isAuthUserLoading
  );
  const [authUser, setAuthUser] = useState(initialState.authUser);

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const { data: user } = await UserApi.getByEmail(authUser.email);
        setAuthUser({ ...user, uid: authUser.uid });
      } else {
        setAuthUser(null);
      }
      setIsAuthUserLoading(false);
    });
  }, []);

  const values = { authUser, setAuthUser, isAuthUserLoading };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
