import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceCard from "../components/Cards/deviceCard";
import { useGetAllDevicesQuery } from "../redux/device";
import PageHeader from "../components/PageHeader";
import PageGridContent from "../components/PageGridContent";
import { useToggle } from "../hooks/useToogle";
import Dialog from "../components/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "../components/DialogContent";
import UsersDialog from "../components/Device/UsersDialog";
import EventsDialog from "../components/Device/EventsDialog";
import EditUserPermissionsDialog from "../components/Device/EditUserPermissionsDialog";
import AddUserDialog from "components/Device/AddUserDialog";
import MapDialog from "components/Device/MapDialog";

export default function Devices() {
  const [filterText, setFilterText] = useState("");
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useGetAllDevicesQuery();
  const detailDialog = useToggle();
  const usersDialog = useToggle();
  const eventsDialog = useToggle();
  const editPermissionsDialog = useToggle();
  const addUserDialog = useToggle();
  const mapDialog = useToggle();

  const devices = data || [];
  const filteredDevices = devices.filter((device) => {
    const deviceName = device.name.toLowerCase();

    return deviceName.indexOf(filterText) > -1;
  });

  return (
    <>
      <PageHeader
        title={t("device.MainTitle")}
        onClick={refetch}
        onChange={setFilterText}
      />
      <PageGridContent loading={isFetching}>
        {filteredDevices.map((device, index) => {
          return (
            <DeviceCard
              key={index}
              device={device}
              onClick={() => detailDialog.open(device)}
              onUsersMenuClick={() => usersDialog.open(device)}
              onEventsMenuClick={() => eventsDialog.open(device)}
              onAddUserMenuClick={() => addUserDialog.open(device)}
              onMapMenuClick={() => mapDialog.open(device)}
            />
          );
        })}
        {filteredDevices.length === 0 && (
          <p className="text-md text-babGray600">{t("noDataFound")}</p>
        )}
      </PageGridContent>
      <Dialog
        icon={<i className="icon-device text-5xl" />}
        open={detailDialog.opened}
        onClose={detailDialog.close}
      >
        <DialogTitle>{t("device.deviceDetail")}</DialogTitle>
        {detailDialog.data && (
          <DialogContent>
            <div
              className={`flex flex-col items-center bg-white text-sm rounded-xl p-4`}
            >
              <div className="bg-white rounded-xl">
                <div className="outline outline-slate-100 border rounded-full h-28 w-28 flex items-center justify-center bg-white shadow-md ">
                  <div className="outline border-2 border-babGray300 outline-babGray100 rounded-full h-12 w-12 flex items-center justify-center shadow-md text-gray-400 font-extrabold tracking-wider">
                    bab
                  </div>
                </div>
              </div>
              <div className="text-babGray900 mt-3 text-center ">
                {detailDialog.data.name}
              </div>
              {detailDialog.data.serialNumber && (
                <div className="text-babGray900 mt-3 text-center ">
                  {detailDialog.data.serialNumber}
                </div>
              )}
              {detailDialog.data.location && (
                <div className="text-babGray900 mt-3 text-center ">
                  {detailDialog.data.location.line}
                </div>
              )}
            </div>
          </DialogContent>
        )}
      </Dialog>
      <UsersDialog
        device={usersDialog.data}
        open={usersDialog.opened}
        onClose={usersDialog.close}
        onEditUserClick={(data) => editPermissionsDialog.open(data)}
      />
      <EventsDialog
        device={eventsDialog.data}
        open={eventsDialog.opened}
        onClose={eventsDialog.close}
      />
      <EditUserPermissionsDialog
        data={editPermissionsDialog.data}
        open={editPermissionsDialog.opened}
        onClose={editPermissionsDialog.close}
        onSubmit={(device) => usersDialog.setData(device)}
      />
      <AddUserDialog
        device={addUserDialog.data}
        open={addUserDialog.opened}
        onClose={addUserDialog.close}
      />
      <MapDialog
        device={mapDialog.data}
        open={mapDialog.opened}
        onClose={mapDialog.close}
      />
    </>
  );
}
