import SearchInput from "../components/searchInput";

const PageHeader = ({ title, onClick, onChange }) => {
  return (
    <>
      <div className="flex">
        <div className="text-3xl text-babGray600 font-medium">{title}</div>
      </div>
      <div className="flex items-center my-3">
        <SearchInput onChange={(e) => onChange(e.target.value)} />
        <div className="ml-auto flex items-center">
          <i className="icon-refresh cursor-pointer" onClick={onClick} />
        </div>
      </div>
    </>
  );
};

export default PageHeader;
