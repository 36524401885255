import Dialog from "components/Dialog";
import DialogTitle from "components/DialogTitle";
import DialogContent from "components/DialogContent";
import Bbinput from "components/common/B-input/input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useUpdateUserMutation } from "redux/user";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import PhoneInput from "components/PhoneInput";
import { useState } from "react";
import UpdatePasswordForm from "./UpdatePasswordForm";
import LoadingButton from "components/LoadingButton";
import { ErrorMsg, SuccessMsg } from "components/Toastify";

export default function EditUserDialog({ open, onClose }) {
  const { t } = useTranslation();
  const { authUser, setAuthUser } = useAuth();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    values: { name: authUser.name, phone: authUser.phone },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label("common.name"),
        phone: yup.string().required().phone().label("common.phone"),
      })
    ),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (form) => {
    const { uid, ...rest } = authUser;
    const userData = { id: uid, ...rest, ...form };
    const response = await updateUser(userData);
    if (response.data) {
      setAuthUser(userData);
      SuccessMsg(t("updatedSuccessfully"));
    } else {
      ErrorMsg(t("anErrorOccured"));
    }
  };

  return (
    <Dialog
      width="max-w-[30rem]"
      height="min-h-[30rem]"
      icon={<i className="icon-create-user text-5xl" />}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("editUserProfile")}</DialogTitle>
      <DialogContent className="flex flex-col justify-between">
        <h2 className="text-lg font-medium my-4">{t("userSettings")}</h2>
        <div className="space-y-2 mt-3">
          <div className="flex flex-col space-y-8">
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Bbinput
                  title={t("common.name")}
                  onChange={(name, value) => onChange(value)}
                  value={value}
                  error={errors.name?.message}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  name="phone"
                  value={value}
                  onChange={onChange}
                  error={errors.phone?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <LoadingButton loading={isLoading} onClick={handleSubmit(onSubmit)}>
            {t("update")}
          </LoadingButton>
        </div>
        <h2 className="text-lg font-medium my-4">{t("passwordSettings")}</h2>
        <UpdatePasswordForm />
      </DialogContent>
    </Dialog>
  );
}
