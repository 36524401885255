import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gates: [],
  status: "idle",
  gateInfo: null,
};

export const GateSlice = createSlice({
  name: "gate",
  initialState,

  reducers: {
    setGate: (state, action) => {
      state.gates = action.payload;
    },
    setSelectedGate: (state, action) => {
      state.gateInfo = action.payload;
    },
    addGate: (state, action) => {
      state.gates.push(action.payload);
    },

    deleteGate: (state, action) => {
      const filtered = state.gates.filter((item) => item.ID !== action.payload);
      state.gates = filtered;
    },

    editGate: (state, action) => {
      const indexOf = state.gates.findIndex(
        (item) => item.ID === action.payload.ID
      );
      state.gates[indexOf].Name = action.payload.Name;
      state.gates[indexOf].GateType = action.payload.GateType;
    },
  },
});

export const { setGate, deleteGate, addGate, editGate, setSelectedGate } =
  GateSlice.actions;

export default GateSlice.reducer;
