import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedGate } from "../redux/gate/gateSlice";
import { setSelectedCredential } from "../redux/credential/credentialSlice";
import { useEffect } from "react";
import { fetchUserGroupListByUserID } from "../redux/userGroup/userGroupApi";
import { ErrorMsg } from "./Toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export default function TooltipMenu(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  let selectuserFilter = props.allUsers?.Data?.filter((item) => {
    return item.ID === props.getuserid;
  });
  let credentialFilter = [];
  const handleClickEditAccessPlan = () => {
    props?.setAccplanid(props?.accessPid);
    props?.setmodaltype("editAccPlan");
    props?.setShowModal(true);
    props?.setFormData({
      ...props?.formData,
      Title: props?.accessPlan.Title,
      UserGroupList: props?.accessPlan.UserGroupList,
      UserList: props?.accessPlan.UserList,
      DailyTimeIntervals: props?.accessPlan.DailyTimeIntervals,
    });
    props.setSelectUsers(props?.accessPlan.UserList);
    props.setSelectUsergroup(props?.accessPlan.UserGroupList);
    const dateSt = new Date(props?.accessPlan.StartDate);
    const formattedDateSt = `${dateSt.getFullYear()}-${(dateSt.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateSt.getDate().toString().padStart(2, "0")}`;
    const dateEnd = new Date(props?.accessPlan.EndDate);
    const formattedDateEnd = `${dateEnd.getFullYear()}-${(
      dateEnd.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateEnd.getDate().toString().padStart(2, "0")}`;
    props.setStartDate(formattedDateSt);
    props.setEndDate(formattedDateEnd);
    let daysArray = props?.accessPlan.DailyTimeIntervals.map(
      (s) => s.DayOfWeek
    );
    props.SetDays(daysArray);
  };

  const [getDataGroup, setGetDataGroup] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const result = await fetchUserGroupListByUserID(props.getuserid);
        setGetDataGroup(result.Data);
      } catch (error) {
        ErrorMsg(`${error}`);
      }
    })();
  }, []);
  return (
    <div>
      {props.degree === "main" && (
        <div className="w-full p-2">{props.content}</div>
      )}
      {props.degree === "rightmenu" && (
        <div className="w-full p-2">{props.content}</div>
      )}
      {props.degree === "assignGroup" && (
        <div className="w-full p-2">{props.content}</div>
      )}
      {props.degree === "language" && (
        <div className="w-full p-2">{props.content}</div>
      )}
      {props.degree === "user" && (
        <div className="space-y-3">
          {props.isAllUser === true && (
            <div
              className="flex items-center hover:bg-babGray100 p-1 rounded-xl"
              onClick={() => {
                props.setShowModal(true);
                props.setmodaltype("assignGroup");
                props.setSelectUsers([]);
                props.setUserid(props.getuserid);
              }}
            >
              <i className="icon-group mr-2 text-xl"></i>
              <div>{t("users.modal.titles.assignGroup")}</div>
            </div>
          )}
          <div
            className="flex items-center  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setShowModal(true);
              props.setmodaltype("edituser");
              props.setSelectUsers(getDataGroup);
              props.setFormData({
                ...props.formData,
                userFName: selectuserFilter[0].FirstName,
                userLName: selectuserFilter[0].LastName,
                useremail: selectuserFilter[0].Email,
                phone: selectuserFilter[0].PhoneNumber,
              });
              props.setUserid(props.getuserid);
            }}
          >
            <i className="icon-edit mr-3 text-lg"></i>
            <div>{t("users.tooltip.edit")}</div>
          </div>
          <div
            onClick={() => {
              props.setUserid(props.getuserid);
              props.setShowModal(true);
              props.setmodaltype("deleteuser");
            }}
            className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
          >
            <i className="icon-delete mr-3 text-lg"></i>
            <div>
              {" "}
              {props.isAllUser === true
                ? t("users.tooltip.delete")
                : t("users.tooltip.remove")}
            </div>
          </div>
        </div>
      )}
      {props.degree === "gate" && (
        <div className="space-y-3">
          <div
            className="flex items-center  hover:bg-babGray100  p-1 rounded-xl mr-4"
            onClick={() => {
              props.setmodaltypeAcc("addAccessPlan");
              props.setShowModalAcc(true);
              props.setGateid(props.gateid);
            }}
          >
            <i className="icon-add-access-plan mr-2"></i>
            <div>{t("location.tooltip.addaccessplan")}</div>
          </div>
          <div
            className="flex items-center  hover:bg-babGray100  p-1 rounded-xl mr-4"
            onClick={() => {
              props.setmodaltype("addAccessPlantoDevice");
              props.setShowModal(true);
              props.setGateid(props.gateid);
            }}
          >
            <i className="icon-add-access-plan mr-2"></i>
            <div>{t("location.tooltip.addaccplanTodevice")}</div>
          </div>
          <div
            className="flex items-center  hover:bg-babGray100  p-1 rounded-xl mr-4"
            onClick={() => {
              props.setmodaltype("editGate");
              props.setShowModal(true);
              props.setGateid(props.gateid);
              dispatch(setSelectedGate(props.gate));
              props.setName(props.gate.Name);
              props.setSelectedValueType(props.gate.GateType);
            }}
          >
            <i className="icon-edit mr-2 "></i>
            <div>{t("location.tooltip.editgate")}</div>
          </div>

          <div
            className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setmodaltype("deleteGate");
              props.setShowModal(true);
              props.setGateid(props.gateid);
            }}
          >
            <i className="icon-delete mr-2"></i>
            <div>{t("location.tooltip.deletegate")}</div>
          </div>
        </div>
      )}
      {props.degree === "userGroup" && (
        <div
          className="flex items-center"
          onClick={() => {
            props.setmodaltype("deleteUserGroup");
            props.setShowModal(true);
            props.setUserGroupid(props.usergroupid);
          }}
        >
          <i className=" icon-delete mr-2"></i>
          <div className="">Delete User Group</div>
        </div>
      )}
      {props.degree === "accessCard" && (
        <div className="space-y-3 w-44">
          {props.accessize === "md" && (
            <div className="space-y-3">
              <div
                className="flex items-center  hover:bg-babGray100 py-1 pl-2 rounded-xl"
                onClick={() => {
                  props.setmodaltype("editAccPlan");
                  props.setShowModal(true);
                  props.setAccplanid(props.accessPid);
                  handleClickEditAccessPlan();
                }}
              >
                <i className="icon-edit mr-2"></i>
                <div>{t(`accessplan.tooltip.editplan`)}</div>
              </div>
              <div
                className="flex items-center hover:bg-babGray100  p-1 rounded-xl"
                onClick={() => {
                  props?.setAccplanid(props?.accessPid);
                  props?.setmodaltype("addUsers");
                  props?.setShowModal(true);
                }}
              >
                <i className="icon-create-user mr-1.5 text-lg"></i>
                <div className="">{t(`accessplan.tooltip.addusers`)}</div>
              </div>
              <div
                className="flex items-center hover:bg-babGray100  p-1 rounded-xl"
                onClick={() => {
                  props.setAccplanid(props.accessPid);
                  props.setmodaltype("addUserGroup");
                  props.setShowModal(true);
                }}
              >
                <i className="icon-create-user-groups mr-1.5 text-lg"></i>
                <div className="">{t(`accessplan.tooltip.addusergroup`)}</div>
              </div>
              <div
                className="flex items-center hover:bg-babGray100  p-1 rounded-xl"
                onClick={() => {
                  props.setmodaltype("copyAccessPlan");
                  props.setShowModal(true);
                }}
              >
                <i className="icon-copy mr-2"></i>
                <div className="">{t(`accessplan.tooltip.makeacopy`)}</div>
              </div>
              <div
                className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
                onClick={() => {
                  props.setAccplanid(props.accessPid);
                  props.setmodaltype("deleteAccPlan");
                  props.setShowModal(true);
                }}
              >
                <i className="icon-delete mr-2 "></i>

                <div className="">{t(`accessplan.tooltip.deleteplan`)}</div>
              </div>
            </div>
          )}
          {props.accessize === "sm" && (
            <div className="space-y-3">
              <div
                className="flex items-center  hover:bg-babGray100 py-1 pl-2 rounded-xl"
                onClick={() => {
                  props?.setmodaltype("editAccPlan");
                  props.setShowModal(true);
                  props.setAccplanid(props.accessPid);
                  handleClickEditAccessPlan();
                }}
              >
                <i className="icon-edit mr-2"></i>
                <div>{t("accessplan.tooltip.editplan")}</div>
              </div>
              <div
                className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
                onClick={() => {
                  props.setmodaltype("removeAccPlan");
                  props.setShowModal(true);
                  props.setAccplanid(props.accessPid);
                }}
              >
                <i className="icon-remove mr-2 "></i>

                <div className="">{t("accessplan.tooltip.removeplan")}</div>
              </div>
            </div>
          )}
        </div>
      )}
      {props.degree === "device" && (
        <div className="space-y-3 p-2 ">
          {props.deviceplace === "devicepage" && (
            <div
              className="flex items-center  hover:bg-babGray100  p-1 rounded-xl"
              onClick={() => {
                props.setmodaltype("editDevice");
                props.setShowModal(true);
                props.setDeviceId(props.device.ID);
              }}
            >
              <i className="icon-date-access-plan mr-2"></i>
              <div>{t("device.tooltip.editdevice")} </div>
            </div>
          )}
          <div className="flex items-center  hover:bg-babGray100 p-1 rounded-xl">
            <i className="icon-check-device-tp mr-2"></i>
            <div>{t("device.tooltip.checkdevice")}</div>
          </div>
          <div className="flex items-center   hover:bg-babGray100 p-1 rounded-xl">
            <i className="icon-restart-device-tp mr-2"></i>
            <div>{t("device.tooltip.restartdevice")}</div>
          </div>
          <div
            className="flex items-center hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setmodaltype("deviceLog");
              props.setShowRight(true);
            }}
          >
            <i className="icon-view-logs-tp mr-2"></i>
            <div>{t("device.tooltip.viewlogs")}</div>
          </div>
          {props.deviceplace === "devicepage" && (
            <div
              className="flex items-center  text-babRed500 hover:bg-babGray100 p-1 rounded-xl"
              onClick={() => {
                props.setmodaltype("deleteDevice");
                props.setShowModal(true);
                props.setDeviceId(props.device.ID);
              }}
            >
              <i className="icon-delete mr-2"></i>
              <div>{t("device.tooltip.deletedevice")}</div>
            </div>
          )}
          {props.deviceplace === "gate" && (
            <div
              className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
              onClick={() => {
                props.setmodaltype("removeDevice");
                props.setShowModal(true);
              }}
            >
              <i className="icon-remove mr-2"></i>
              <div className="">{t("device.tooltip.removedevice")}</div>
            </div>
          )}
        </div>
      )}
      {props.degree === "credentialCard" && (
        <div className="space-y-3">
          <div
            className="flex items-center  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setmodaltype("editBeacon");
              props.setShowModal(true);
              props.setFormData({
                ...props.formData,
                RAWData: credentialFilter[0].RAWData,
                Name: credentialFilter[0].Name,
              });
              props.setCredID(props.credID);
              dispatch(setSelectedCredential(props.credential));
              props.setSelectUsers(props.credential.UserID);
              props.setSelectedValueType(props.credential.SensorType);
            }}
          >
            <i className="icon-edit mr-3 text-lg"></i>
            <div>{t("credentials.tooltip.edit")}</div>
          </div>
          <div
            className="flex items-center  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setShowModal(true);
              props.setmodaltype("changeUser");
              dispatch(setSelectedCredential(props.credential));
              props.setSelectUsers([]);
            }}
          >
            <i className="icon-restart-device-tp mr-2 text-lg"></i>
            <div>{t("credentials.tooltip.changeUser")}</div>
          </div>
          <div
            className="flex items-center  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              // props.setShowModal(true);
              // props.setmodaltype("viewLogs");
            }}
          >
            <i className="icon-view-logs-tp mr-2 text-lg"></i>
            <div>{t("credentials.tooltip.viewLogs")}</div>
          </div>
          <div
            className="flex items-center  text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
            onClick={() => {
              props.setShowModal(true);
              props.setmodaltype("removeUser");
              dispatch(setSelectedCredential(props.credential));
            }}
          >
            <i className="icon-false mr-3 text-lg"></i>
            <div>{t("credentials.tooltip.removeUser")}</div>
          </div>
          <div
            onClick={() => {
              props.setShowModal(true);
              props.setmodaltype("deleteCredCard");
              dispatch(setSelectedCredential(props.credential));
            }}
            className="flex items-center text-babRed500  hover:bg-babGray100 p-1 rounded-xl"
          >
            <i className="icon-delete mr-3 text-lg"></i>
            <div>{t("credentials.tooltip.delete")}</div>
          </div>
        </div>
      )}
    </div>
  );
}
