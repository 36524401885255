import { Navigate, Route, Routes } from "react-router-dom";
import LoginRegister from "../pages/LoginRegister";
import Guest from "../components/Auth/Guest";
import TermsAndConditions from "pages/TermsAndConditions";
import routeConfig from "config/route";

const GuestLayout = () => {
  return (
    <Routes>
      <Route path={routeConfig.login} element={<LoginRegister />} />
      <Route path={routeConfig.signup} element={<LoginRegister />} />
      <Route path={routeConfig.forgotPassword} element={<LoginRegister />} />
      <Route path={routeConfig.guest} element={<Guest />} />
      <Route
        path={routeConfig.termsConditions}
        element={<TermsAndConditions />}
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default GuestLayout;
