import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserCard from "../components/Cards/userCard";
import { useGetAllUsersQuery } from "../redux/user";
import PageHeader from "../components/PageHeader";
import PageGridContent from "../components/PageGridContent";
import { useToggle } from "../hooks/useToogle";
import Dialog from "../components/Dialog";
import DialogTitle from "../components/DialogTitle";
import DialogContent from "../components/DialogContent";

export default function Users() {
  const [filterText, setFilterText] = useState("");
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useGetAllUsersQuery();
  const detailDialog = useToggle();

  const users = data || [];

  const filteredUsers = users.filter((user) => {
    const { name, email, phone } = user;
    const userName = name ? name.toLowerCase() : "";
    const userPhone = phone ? phone.toLowerCase() : "";
    const userEmail = email ? email.toLowerCase() : "";

    return (
      userName.indexOf(filterText) > -1 ||
      userPhone.indexOf(filterText) > -1 ||
      userEmail.indexOf(filterText) > -1
    );
  });

  return (
    <>
      <PageHeader
        title={t("users.sidebar.title")}
        onClick={refetch}
        onChange={setFilterText}
      />
      <PageGridContent loading={isFetching}>
        {filteredUsers.map((user, index) => (
          <UserCard
            key={index}
            user={user}
            onClick={() => detailDialog.open(user)}
          />
        ))}
        {filteredUsers.length === 0 && (
          <p className="text-md text-babGray600">{t("noDataFound")}</p>
        )}
      </PageGridContent>
      <Dialog open={detailDialog.opened} onClose={detailDialog.close}>
        <DialogTitle>{t("users.userDetail")}</DialogTitle>
        {detailDialog.data && (
          <DialogContent>
            <div className="w-full justify-center my-2 flex">
              <i className="icon-users-groups text-8xl m-2"></i>
            </div>
            <div className="flex flex-col ">
              <div className="text-center font-medium whitespace-nowrap capitalize">
                {detailDialog.data.name}
              </div>
              <div className="text-sm text-babGray600 text-center mt-2">
                {detailDialog.data.phone}
              </div>
              <div className="text-sm text-babGray600 mt-2 whitespace-nowrap text-center">
                {detailDialog.data.email}
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
