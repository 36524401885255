import moment from "moment";

export const Alldates = [
  {
    id: 0,
    name: "MON",
    fullname: "Monday",
  },
  {
    id: 1,
    name: "TUE",
    fullname: "Tuesday",
  },
  {
    id: 2,
    name: "WED",
    fullname: "Wednesday",
  },
  {
    id: 3,
    name: "THU",
    fullname: "Thursday",
  },
  {
    id: 4,
    name: "FRI",
    fullname: "Friday",
  },
  {
    id: 5,
    name: "SAT",
    fullname: "Saturday",
  },
  {
    id: 6,
    name: "SUN",
    fullname: "Sunday",
  },
];

export function translateDayNames(t) {
  return Alldates.map((date) => ({
    ...date,
    translatefullname: t(`common.days.${date.fullname.toLowerCase()}`),
    translateName: t(`common.days.${date.name.toLowerCase()}`),
  }));
}

export const DateUtil = {
  toDateTimeString(seconds) {
    return moment.unix(seconds).format("DD/MM/YYYY HH:mm");
  },
};
