const ToggleButton = ({ value, onClick, children, selected }) => {
  const selectedClasses = selected ? "bg-babGray900 text-white" : "";
  return (
    <div
      className={`text-babGray900 cursor-pointer text-sm rounded-3xl border-x border-y py-1 px-3 ${selectedClasses}`}
      onClick={() => onClick(value)}
    >
      {children}
    </div>
  );
};

export default ToggleButton;
