import React from "react";
import { useTranslation } from "react-i18next";

export default function SearchInput(props) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      <i className="icon-search text-babGray400"></i>
      <input
        autoComplete="off"
        className=" bg-transparent h-9 pl-2 rounded-full w-full text-sm focus:outline-none text-slate-600"
        id="Search"
        type="search"
        name="search"
        placeholder={
          props?.placeholder ? props?.placeholder : t("common.search")
        }
        onChange={props.onChange}
      />
    </div>
  );
}
