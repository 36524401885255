import React from 'react'
import LoginRegister from '../components/Auth/LoginRegister'

export default function LoginRegisterPage() {
  return (
    <div>
      <LoginRegister/>
    </div>
  )
}
