import React, { useMemo } from "react";
import InfoCard from "../components/Cards/infoCard";
import Table from "../components/Table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useGetAllUsersQuery } from "redux/user";
import { useGetAllDevicesQuery, useGetAllEventsQuery } from "redux/device";
import { DateUtil } from "utils/date";

export default function Dashboard() {
  const { t } = useTranslation();
  const { data: usersData, isFetching: usersFetching } = useGetAllUsersQuery();
  const { data: devicesData, isFetching: devicesFetching } =
    useGetAllDevicesQuery();
  const { data: eventsData, isFetching: eventsFetching } = useGetAllEventsQuery(
    {
      startDate: new Date(moment().subtract(6, "months").format("YYYY-MM-DD")),
      endDate: new Date(moment().format("YYYY-MM-DD")),
    }
  );

  const users = usersData ?? [];
  const devices = devicesData ?? [];

  const eventTableRows = useMemo(
    () =>
      (eventsData ?? []).map((event) => [
        DateUtil.toDateTimeString(event.timestamp.seconds),
        event.user?.name ?? "anonymous",
        t(`eventEnum.${event.direction}`),
        event.device?.name ?? "unknown",
        event.location
          ? `${event.location.county}, ${event.location.city}`
          : "",
      ]),
    [eventsData]
  );

  const tableHeader = [
    t("dashboard.table.columns.time"),
    t("dashboard.table.columns.user/guest"),
    t("dashboard.table.columns.event"),
    t("dashboard.table.columns.device/door"),
    t("location.location"),
  ];

  return (
    <div className="ml-3">
      <div className="text-2xl text-babGray700 mb-4 font-semibold">
        {t("dashboard.MainTitle")}
      </div>
      <div className="lg:flex">
        <div className="w-full">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-3 ">
            <InfoCard
              isLoading={usersFetching}
              logo={<i className="icon-dashboard-profile"></i>}
              data={users.length}
              name={t(`dashboard.widgets.users`)}
            />
            <InfoCard
              isLoading={devicesFetching}
              logo={<i className="icon-dashboard-device"></i>}
              data={devices.length}
              name={t(`dashboard.widgets.devices`)}
            />
          </div>
          <div className="mt-9 mb-2">
            <div className="text-babGray400 m-1 text-lg">
              {t("dashboard.table.title")}
            </div>
            <Table
              isLoading={eventsFetching}
              th={tableHeader}
              tr={eventTableRows}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
