import Dialog from "../Dialog";
import DialogTitle from "../DialogTitle";
import DialogContent from "../DialogContent";
import { getInitials } from "../../utils/string";
import { useEffect, useState } from "react";
import { useUpdateDeviceMutation } from "../../redux/device";
import { LoadAnimation } from "../LoadAnimation";
import { useTranslation } from "react-i18next";

export default function EditUserPermissionsDialog({
  open,
  onClose,
  data,
  onSubmit,
}) {
  const user = data?.user;
  const device = data?.device;

  const [ownersPermission, setOwnersPermission] = useState(false);
  const [shareOwnersPermission, setShareOwnersPermission] = useState(false);
  const [allowedUsersPermission, setAllowedUsersPermission] = useState(false);

  const [updateDevice, { isLoading }] = useUpdateDeviceMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (open && user && device) {
      const hasShareOwnersPermission = (device.shareOwners ?? []).includes(
        user.id
      );
      const hasAllowedUsersPermission = (device.allowedUsers ?? []).includes(
        user.id
      );
      const hasOwnersPermission = (device.owners ?? []).includes(user.id);
      setOwnersPermission(hasOwnersPermission);
      setShareOwnersPermission(hasShareOwnersPermission);
      setAllowedUsersPermission(hasAllowedUsersPermission);
    }
  }, [open, user, device]);

  useEffect(() => {
    setShareOwnersPermission(ownersPermission);
  }, [ownersPermission]);

  useEffect(() => {
    setAllowedUsersPermission(shareOwnersPermission);
  }, [shareOwnersPermission]);

  const handleClose = () => {
    onClose();
  };

  const updateList = (permission, list) => {
    const newList = list.slice();
    if (permission) {
      if (!newList.includes(user.id)) {
        newList.push(user.id);
      }
    } else {
      const index = newList.findIndex((id) => id === user.id);
      if (index >= 0) {
        newList.splice(index, 1);
      }
    }
    return newList;
  };

  const handleSubmit = async () => {
    const allowedUsers = updateList(
      allowedUsersPermission,
      device.allowedUsers || []
    );
    const shareOwners = updateList(
      shareOwnersPermission,
      device.shareOwners || []
    );
    const owners = updateList(ownersPermission, device.owners || []);
    const deviceData = { ...device, allowedUsers, shareOwners, owners };
    await updateDevice(deviceData).unwrap();
    onSubmit(deviceData);
    handleClose();
  };

  if (!user || !device) {
    return null;
  }

  return (
    <Dialog
      width="max-w-[24rem]"
      height="min-h-[10rem]"
      icon={<i className="icon-edit-user text-5xl" />}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("updateUserAuthorization")}</DialogTitle>
      <DialogContent>
        <div className="px-2">
          <div className="flex items-center">
            <div className="rounded-full bg-babGray700 text-white p-2 w-12 h-12 flex justify-center items-center text-xl font-bold">
              {getInitials(user.name)}
            </div>
            <div className="flex flex-col ml-3">
              <h2 className="font-medium">{user.name}</h2>
              <p>{user.phone}</p>
            </div>
          </div>
          <h3 className="my-4">{t("selectUserAuthority")}</h3>
          <div className="space-y-4">
            <div className="flex items-center text-babGray900">
              <input
                type="checkbox"
                className="accent-gray-500 mr-3"
                checked={ownersPermission}
                onChange={(event) => setOwnersPermission(event.target.checked)}
              />{" "}
              {t("owners")}
            </div>
            <div className="flex items-center text-babGray900">
              <input
                type="checkbox"
                className="accent-gray-500 mr-3"
                disabled={ownersPermission}
                checked={shareOwnersPermission}
                onChange={(event) =>
                  setShareOwnersPermission(event.target.checked)
                }
              />{" "}
              {t("sharingAuthority")}
            </div>
            <div className="flex items-center text-babGray900">
              <input
                type="checkbox"
                className="accent-gray-500 mr-3"
                checked={allowedUsersPermission}
                disabled={shareOwnersPermission}
                onChange={(event) =>
                  setAllowedUsersPermission(event.target.checked)
                }
              />{" "}
              {t("allowedUsers")}
            </div>
            <div className="flex justify-around mt-4">
              <button
                disabled={isLoading}
                className="uppercase bg-transparent text-babGray900 font-semibold"
                onClick={handleClose}
              >
                {t("cancel")}
              </button>
              <button
                className="uppercase bg-babGray700 rounded-xl p-2 text-white font-medium relative"
                onClick={handleSubmit}
              >
                <p className={`${isLoading ? "invisible" : ""}`}>
                  {t("update")}
                </p>
                {isLoading && (
                  <LoadAnimation
                    viewType={"border-dotted"}
                    bordersize={"border-3"}
                    Color={"border-white"}
                    sizeH={"h-6"}
                    sizeW={"w-6"}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
