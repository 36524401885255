import Dialog from "components/Dialog";
import DialogTitle from "components/DialogTitle";
import DialogContent from "components/DialogContent";
import Bbinput from "components/common/B-input/input";
import { useUpdateDeviceMutation } from "redux/device";
import { LoadAnimation } from "components/LoadAnimation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { useLazyGetByEmailQuery } from "redux/user";
import { ErrorMsg } from "components/Toastify";
import { useTranslation } from "react-i18next";

export default function AddUserDialog({ open, onClose, device }) {
  const [ownersPermission, setOwnersPermission] = useState(false);
  const [shareOwnersPermission, setShareOwnersPermission] = useState(false);
  const [allowedUsersPermission, setAllowedUsersPermission] = useState(false);

  const { t } = useTranslation();
  const [getUserByEmail, { isFetching }] = useLazyGetByEmailQuery();
  const [updateDevice, { isLoading }] = useUpdateDeviceMutation();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: { email: "" },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required().email().label("common.email"),
      })
    ),
  });

  useEffect(() => {
    setShareOwnersPermission(ownersPermission);
  }, [ownersPermission]);

  useEffect(() => {
    setAllowedUsersPermission(shareOwnersPermission);
  }, [shareOwnersPermission]);

  const handleClose = () => {
    reset();
    onClose();
    setOwnersPermission(false);
    setShareOwnersPermission(false);
    setAllowedUsersPermission(false);
  };

  const onSubmit = async (form) => {
    if (
      !ownersPermission &&
      !shareOwnersPermission &&
      !allowedUsersPermission
    ) {
      return ErrorMsg(t("userPermissionMustBeSelected"));
    }
    const { data: user, error } = await getUserByEmail(form.email);
    if (error) {
      ErrorMsg(t("userNotFoundRegisteredWithThisEmail"));
    } else {
      const owners = [...(device.owners ?? [])];
      const shareOwners = [...(device.shareOwners ?? [])];
      const allowedUsers = [...(device.allowedUsers ?? [])];
      if (
        device.owner === user.id ||
        owners.includes(user.id) ||
        shareOwners.includes(user.id) ||
        allowedUsers.includes(user.id)
      ) {
        return ErrorMsg(t("userAlreadySharedWithThisDevice"));
      }
      if (ownersPermission) {
        owners.push(user.id);
      }
      if (shareOwnersPermission) {
        shareOwners.push(user.id);
      }
      if (allowedUsersPermission) {
        allowedUsers.push(user.id);
      }
      const deviceData = { ...device, allowedUsers, shareOwners, owners };
      await updateDevice(deviceData).unwrap();
      handleClose();
    }
  };

  if (!device) return null;

  return (
    <Dialog
      width="max-w-[30rem]"
      icon={<i className="icon-create-user text-5xl" />}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("addUser")}</DialogTitle>
      <DialogContent>
        <div className={`flex flex-col bg-white text-sm rounded-xl p-4`}>
          <div className="space-y-2 mt-3">
            <div className="flex flex-col">
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Bbinput
                    title={t("common.email")}
                    type="email"
                    name="email"
                    onChange={(name, value) => onChange(value)}
                    value={value}
                    error={errors.email?.message}
                  />
                )}
              />
              <h3 className="my-4">{t("selectUserAuthority")}</h3>
              <div className="space-y-4">
                <div className="flex items-center text-babGray900">
                  <input
                    type="checkbox"
                    className="accent-gray-500 mr-3"
                    checked={ownersPermission}
                    onChange={(event) =>
                      setOwnersPermission(event.target.checked)
                    }
                  />{" "}
                  {t("owners")}
                </div>
                <div className="flex items-center text-babGray900">
                  <input
                    type="checkbox"
                    className="accent-gray-500 mr-3"
                    disabled={ownersPermission}
                    checked={shareOwnersPermission}
                    onChange={(event) =>
                      setShareOwnersPermission(event.target.checked)
                    }
                  />{" "}
                  {t("sharingAuthority")}
                </div>
                <div className="flex items-center text-babGray900">
                  <input
                    type="checkbox"
                    className="accent-gray-500 mr-3"
                    checked={allowedUsersPermission}
                    disabled={shareOwnersPermission}
                    onChange={(event) =>
                      setAllowedUsersPermission(event.target.checked)
                    }
                  />{" "}
                  {t("allowedUsers")}
                </div>
              </div>
            </div>
            <div className="flex justify-around mt-4">
              <button
                disabled={isLoading || isFetching}
                className="uppercase bg-transparent text-babGray900 font-semibold"
                onClick={handleClose}
              >
                {t("cancel")}
              </button>
              <button
                className="uppercase bg-babGray700 rounded-xl py-2 px-4 text-white font-medium relative"
                onClick={handleSubmit(onSubmit)}
              >
                <p className={`${isFetching || isLoading ? "invisible" : ""}`}>
                  {t("add")}
                </p>
                {(isFetching || isLoading) && (
                  <LoadAnimation
                    viewType={"border-dotted"}
                    bordersize={"border-3"}
                    Color={"border-white"}
                    sizeH={"h-6"}
                    sizeW={"w-6"}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
