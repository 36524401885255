import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Donkey from "../../../assets/img/sidebar/donkey.svg";
import Tooltip from "../../tooltip";
import { useTranslation } from "react-i18next";
import routeConfig from "config/route";

export default function FixBarL(props) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const location = useLocation();

  const menu = [
    {
      id: 1,
      name: "Dashboard",
      to: "/dashboard",
      tooltip: t("dashboard.MainTitle"),
      icon: <i className="icon-dashboard text-4xl m-2"></i>,
      isSidebar: false,
    },
    // {
    //   id: 2,
    //   name: "Locations",
    //   to: "/locations",
    //   tooltip:t('location.MainTitle'),
    //   icon: <i className="icon-locations text-4xl m-2"></i>,
    //   isSidebar: true,
    // },
    {
      id: 3,
      name: "Users",
      to: "/users",
      tooltip: t("users.sidebar.title"),
      icon: <i className="icon-users-groups text-4xl m-2"></i>,
      isSidebar: true,
    },
    {
      id: 4,
      name: "Devices",
      to: "/devices",
      tooltip: t("device.MainTitle"),
      icon: <i className="icon-device text-4xl m-2"></i>,
      isSidebar: false,
    },
    // {
    //   id: 5,
    //   name: "Credentials",
    //   to: "/credentials",
    //   tooltip:t('credentials.MainTitle'),
    //   icon: <i className="icon-credentials text-4xl m-2"></i>,
    //   isSidebar: true,
    // },
    // {
    //   id: 6,
    //   name: "Access Plans",
    //   to: "/access_plans",
    //   tooltip:t('accessplan.MainTitle'),
    //   icon: <i className="icon-access-plans text-4xl m-2"></i>,
    //   isSidebar: false,
    // },
  ];
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/dashboard" ||
      location.pathname === "/access_plans" ||
      location.pathname === "/devices"
    ) {
      props.setShowSidebar(false);
      localStorage.setItem("sidebarOpen", "false");
    }
  });
  const handleClick = (isSidebar, name, id, toNav) => {
    nav(toNav);
    if (isSidebar === true) {
      props.setShowSidebar(true);
      props.setIssidebar(true);
      localStorage.setItem("sidebarOpen", "true");
    } else {
      props.setShowSidebar(false);
      props.setIssidebar(false);
      localStorage.setItem("sidebarOpen", "false");
    }
    props.setActiveMenu(name || location.pathname);
    props.setMenuid(id);
  };
  return (
    <div className="py-3 px-3 shadow-xl shadow-babGray200 bg-white">
      <div className="flex justify-center mt-4">
        <img
          className="cursor-pointer"
          alt="logosm"
          width={46}
          height={50}
          src={Donkey}
          onClick={() => nav(routeConfig.dashboard)}
        />
      </div>
      <div className="mt-10 w-full">
        {menu.map((menuItem, idx) => {
          return (
            <div
              key={idx}
              className={`${
                props.menuid === menuItem.id && "rounded-full bg-babGray100 "
              } text-gray-500 hover:bg-black hover:bg-opacity-10 p-1 mb-2 hover:rounded-full text-3xl flex items-center justify-center w-full cursor-pointer`}
              onClick={() => {
                handleClick(
                  menuItem.isSidebar,
                  menuItem.name,
                  menuItem.id,
                  menuItem.to
                );
                localStorage.setItem("menuid", menuItem.id);
              }}
            >
              <Tooltip
                click="hover"
                name={menuItem.icon}
                content={menuItem.tooltip}
                type={"main"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
