import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Bbinput from "components/common/B-input/input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import LoadingButton from "components/LoadingButton";
import AuthApi from "api/auth";
import { ErrorMsg, SuccessMsg } from "components/Toastify";

export default function UpdatePasswordForm() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    values: { password: "", confirmPassword: "" },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().required().password().label("common.password"),
        confirmPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null], t("passwordsMustMatch"))
          .label("common.confirmPassword"),
      })
    ),
  });

  const onSubmit = async (form) => {
    setLoading(true);
    try {
      await AuthApi.updatePassword(form.password);
      SuccessMsg(t("updatedSuccessfully"));
      reset();
    } catch {
      ErrorMsg(t("anErrorOccured"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="space-y-2 mt-3">
        <div className="flex flex-col space-y-8">
          <Controller
            name="password"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Bbinput
                title={t("common.password")}
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(name, value) => onChange(value)}
                value={value}
                error={errors.password?.message}
                endIcon={
                  <i
                    className={`icon ${
                      showPassword ? "icon-eye-off" : "icon-eye"
                    } text-xl cursor-pointer`}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                }
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Bbinput
                title={t("common.confirmPassword")}
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                onChange={(name, value) => onChange(value)}
                value={value}
                error={errors.confirmPassword?.message}
                endIcon={
                  <i
                    className={`icon ${
                      showConfirmPassword ? "icon-eye-off" : "icon-eye"
                    } text-xl cursor-pointer`}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                }
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>
          {t("update")}
        </LoadingButton>
      </div>
    </div>
  );
}
