import { setLocale, addMethod, string } from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

const mixedRules = [
  { name: "required", param: null },
  { name: "oneOf", param: "values" },
];

const stringRules = [
  { name: "length", param: "length" },
  { name: "min", param: "min" },
  { name: "max", param: "max" },
  { name: "matches", param: "regex" },
  { name: "email", param: null },
  { name: "url", param: null },
  { name: "uuid", param: null },
  { name: "trim", param: null },
  { name: "lowercase", param: null },
  { name: "uppercase", param: null },
  { name: "password", param: null },
];

const numberRules = [
  { name: "min", param: "min" },
  { name: "max", param: "max" },
  { name: "lessThan", param: "less" },
  { name: "moreThan", param: "more" },
  { name: "positive", param: null },
  { name: "negative", param: null },
  { name: "integer", param: null },
];

const dateRules = [
  { name: "min", param: "min" },
  { name: "max", param: "max" },
];

const booleanRules = [{ name: "isValue", param: "value" }];

const arrayRules = [
  { name: "min", param: "min" },
  { name: "max", param: "max" },
  { name: "length", param: "length" },
];

const ruleTypes = {
  mixed: mixedRules,
  string: stringRules,
  number: numberRules,
  date: dateRules,
  boolean: booleanRules,
  array: arrayRules,
};

export const buildYupLocale = (error, t) => {
  const locale = {};

  Object.entries(ruleTypes).forEach(([key, value]) => {
    locale[key] = value.reduce((rules, item) => {
      rules[item.name] = (args) =>
        t(`validation.${key}.${item.name}`, { ...args });

      return rules;
    }, {});
  });

  setLocale(locale);

  addMethod(
    string,
    "password",
    function (errorMessage = t("validation.string.password")) {
      return this.test("password", errorMessage, function (value) {
        const { path, createError } = this;
        const re =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~.,]).{8,}$/g;
        return !!re.exec(value) || createError({ path, errorMessage });
      });
    }
  );

  addMethod(
    string,
    "phone",
    function (errorMessage = t("validation.string.phone")) {
      return this.test("phone", errorMessage, function (value, context) {
        const countryCode = context.options.context?.countryCode;
        const { path, createError } = this;

        return (
          isValidPhoneNumber(value, String(countryCode).toUpperCase()) ||
          createError({ path, errorMessage })
        );
      });
    }
  );
};
