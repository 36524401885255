import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SidebarL from "../components/sidebars/leftSidebar/sidebar";
import RightBar from "../components/sidebars/rightSidebar/rightBar";
import Dashboard from "../pages/Dashboard";
import Devices from "../pages/Devices";
import Locations from "../pages/Locations";
import Users from "../pages/Users";
import AccessPlans from "../pages/AccessPlans";
import Credentials from "../pages/Credentials";
import routeConfig from "config/route";

const AuthLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSidebar, setIssidebar] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path === "/dashboard") {
      setShowSidebar(false);
      localStorage.setItem("sidebarOpen", "false");
      setIssidebar(false);
    }
  }, [path]);

  return (
    <div className="flex w-full overflow-hidden">
      <SidebarL
        openBar={showSidebar}
        setOpenBar={setShowSidebar}
        isSidebar={isSidebar}
        setIssidebar={setIssidebar}
      />
      <div className="flex-col bg-babGray50 flex align-items-center sticky top-0 h-screen overflow-y-auto inset-0 w-full scrollbarContent z-30">
        <div className="w-full max-w-screen-2xl py-8 px-8 min-h-screen">
          <Routes>
            <Route
              path={routeConfig.dashboard}
              element={<Dashboard setShowSidebar={setShowSidebar} />}
            />
            <Route path={routeConfig.users} element={<Users />} />
            <Route path={routeConfig.devices} element={<Devices />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </div>
      </div>
      <RightBar />
    </div>
  );
};

export default AuthLayout;
