import React, { useState } from "react";
import TooltipMenu from "./tooltipMenu";

export default function Tooltip(props) {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div>
      {/* with click */}
      {props.click === "onclick" && (
        <div>
          {openMenu === true && (
            <div
              className={`absolute text-sm rounded-lg z-40
              
       ${
         props.type === "userGroup" &&
         "absolute -mt-14 cursor-pointer right-10 bg-white shadow-md rounded-xl border-slate-100 border-2 p-2 text-md text-slate-500"
       }
       ${
         props.type === "user" &&
         "absolute left-4 w-56 -mt-1 cursor-pointer  bg-white shadow-md rounded-xl border-slate-100 border-2 p-3"
       }
       ${
         props.type === "credentialCard" &&
         "absolute left-4 w-56 -mt-1 cursor-pointer  bg-white shadow-md rounded-xl border-slate-100 border-2 p-3"
       }
       ${
         props.type === "gate" &&
         "absolute right-16 bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
       }
       ${
         props.type === "device" &&
         "absolute bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
       }
       ${props.type === "main" && "ml-14 text-white bg-babGreen200"}`}
              onMouseLeave={() => setOpenMenu(false)}
            >
              <TooltipMenu
                setShowModalAcc={props.setShowModalAcc}
                setmodaltypeAcc={props.setmodaltypeAcc}
                isAllUser={props.isAllUser}
                setSelectUsers={props.setSelectUsers}
                setShowModal={props.setShowModal}
                setShowRight={props.setShowRight}
                setmodaltype={props.setmodaltype}
                setUserGroupid={props.setUserGroupid}
                usergroupid={props.usergroupid}
                degree={props.type}
                content={props.content}
                setGateid={props.setGateid}
                gateid={props.gateid}
                gate={props.gate}
                setName={props.setName}
                setFormData={props.setFormData}
                formData={props.formData}
                setSelectedValueType={props.setSelectedValueType}
                setUserid={props.setUserid}
                getuserid={props.getuserid}
                allUsers={props.allUsers}
                setDeviceId={props.setDeviceId}
                device={props.device}
                deviceplace={props.deviceplace}
                credential={props.credential}
                setCredID={props.setCredID}
                credID={props.credID}
              />
            </div>
          )}
          <div
            className="cursor-pointer"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {props.name}
          </div>{" "}
        </div>
      )}
      {/* with hover */}
      {props.click === "hover" && (
        <div className="has-tooltipp z-40">
          <div
            className={`tooltipp text-sm z-40 ${
              props.type === "gate" &&
              "absolute right-16 bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
            }
     ${props.type === "main" && "ml-16 bg-white shadow-xl rounded-full px-2"}
     ${props.type === "rightmenu" && "absolute right-16 bg-white px-2 shadow-xl rounded-full "}
     ${
       props.type === "assignGroup" &&
       "mt-3 bg-white shadow-xl rounded-full px-2"
     }
     ${
      props.type === "language" &&
      "absolute right-5 mt-[2rem] bg-white shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
    }
     ${
       props.type === "device" &&
       "ml-3 bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
     }
     ${
       props.type === "accessCard" &&
       "ml-4 bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
     }
     ${
       props.type === "accessPlan" &&
       "ml-4 bg-white p-2 shadow-md rounded-xl border-slate-100 border-2 cursor-pointer"
     }
     `}
          >
            <TooltipMenu
              accessize={props.accessize}
              setGateid={props.setGateid}
              gateid={props.gateid}
              degree={props.type}
              content={props.content}
              setShowModal={props.setShowModal}
              setShowRight={props.setShowRight}
              setmodaltype={props.setmodaltype}
              device={props.device}
              deviceplace={props.deviceplace}
              setDeviceId={props.setDeviceId}
              setAccplanid={props.setAccplanid}
              accessPid={props.accessPid}
              plan={props.plan}
              accessPlan={props.accessPlan}
              setAccPlanID={props.setAccPlanID}
              setFormData={props.setFormData}
              formData={props.formData}
              SetDays={props.SetDays}
              setSelectUsers={props.setSelectUsers}
              setSelectUsergroup={props.setSelectUsergroup}
              setStartDate={props.setStartDate}
              setEndDate={props.setEndDate}
            />
          </div>
          <div className="cursor-pointer" onClick={() => setOpenMenu(false)}>
            {props.name}
          </div>
        </div>
      )}
    </div>
  );
}
