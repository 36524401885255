import AppLoader from "./AppLoader";

const PageGridContent = ({ children, loading }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4`}
    >
      {loading && <AppLoader />}
      {children}
    </div>
  );
};

export default PageGridContent;
