import { Children, cloneElement, isValidElement } from "react";

const ToggleButtonGroup = ({ children, onChange, value, className = "" }) => {
  return (
    <div className={`flex space-x-2 ${className}`}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          onClick: (value) => onChange?.(value),
          selected: child.props.value === value,
        });
      })}
    </div>
  );
};

export default ToggleButtonGroup;
